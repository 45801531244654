import React, { useState, useRef } from "react";
import { fetchExcel } from "../../app/helpers/management"
import { Message } from "../../components/Message";
import { returnValues, removeValue, validateData } from "../../components/Form/Formfunctions";
import { FormFields } from "../../components/Form/Fields";
import { FormInputSubmit } from "../../components/Form/Input/Submit";
import { ContentWrapper } from "../../components/Content/Wrapper";

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function AppManagementRentalcost({ parents, current }) {

    const [error, setError] = useState();
    const [errors, setErrors] = useState();
    const [message, setMessage] = useState();
    const [values, setValues] = useState({});
    const [isSubmitted, setSubmitted] = useState(false);
    const ref = useRef(null);

    const months = {
        1: 'Januari',
        2: 'Februari',
        3: 'Maart',
        4: 'April',
        5: 'Mei',
        6: 'Juni',
        7: 'Juli',
        8: 'Augustus',
        9: 'September',
        10: 'Oktober',
        11: 'November',
        12: 'December',
    };

    const getYears = (minYear) => {
        let today = new Date();
        let years = {};

        for (let i = today.getFullYear(); i >= minYear; i--) {
            years = { ...years, [i]: i };
        }
        return years
    }

    let formFields = {
        location: {
            type: "location",
            options: { locationId: "locationId" },
            label: { locationId: "Organisatie/locatie" },
        },
        from: {
            type: 'fieldset',
            options: {
                fromMonth: {
                    type: "select",
                    label: 'Vanaf maand',
                    options: months,
                    value: values['fromMonth'] || '',
                    rules: ['required'],
                    className: "col-6"
                },
                fromYear: {
                    type: "select",
                    label: 'Vanaf jaar',
                    options: getYears(2023),
                    value: values['fromYear'] || '',
                    rules: ['required'],
                    className: "col-6"
                },
            }
        },
        to: {
            type: 'fieldset',
            options: {
                toMonth: {
                    type: "select",
                    label: 'Tot en met maand',
                    options: months,
                    value: values['toMonth'] || '',
                    rules: ['required'],
                    className: "col-6"
                },
                toYear: {
                    type: "select",
                    label: 'Tot en met jaar',
                    options: getYears(2023),
                    value: values['toYear'] || '',
                    rules: ['required'],
                    className: "col-6"
                },
            }
        },
        groupedOnLocation: {
            type: "checkbox",
            label: 'Weergeven per locatie',
            value: values['groupedOnLocation'],
        },
        groupedOndDepartment: {
            type: "checkbox",
            label: 'Weergeven per afdeling',
            value: values['groupedOndDepartment'],
        }
    }

    const setInputValues = (key, value) => {

        setMessage(null);

        // reset errors
        if (isSubmitted === true) {
            setSubmitted(false);
        }
        if (errors) {
            setErrors(removeValue(errors, key, null));
        }

        setValues(returnValues(values, key, value, null));
    }

    const setInputErrors = (key, value, identifier = null) => {

        // add or remove error
        let newErrors = errors;
        if (value !== undefined && value !== null) {
            newErrors = returnValues(errors, key, value, identifier);
        }
        else {
            newErrors = removeValue(errors, key, identifier);
        }
        setErrors(newErrors);
    }

    const delay = ms => new Promise(res => setTimeout(res, ms));

    const closeDownload = async (delayTime = 2500) => {
        await delay(delayTime);
    };

    const downloadFile = async () => {
        try {
            const callData = {
                documentType: 'rentalcost',
                filters: values
            }
            await fetchExcel(callData).then((res) => {

                if (res.data.size < 100 && setError !== undefined) {
                    setError('Er is iets foutgegaan met het ophalen van het bestand');
                }
                else if (res.data.size < 100) {
                    closeDownload();
                    alert('Er is iets foutgegaan met het ophalen van het bestand');
                }
                else {
                    const data = res.data;
                    var dataUrl = URL.createObjectURL(new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));

                    let aLink = ref.current;
                    aLink.href = dataUrl;
                    aLink.setAttribute('download', 'Verhuurkosten');
                    aLink.click();
                    URL.revokeObjectURL(dataUrl);

                    setMessage('Bestand verhuurkosten succesvol aangemaakt');
                }
                closeDownload();
            });

        } catch (error) {
            if (setError !== undefined) {
                setError("Er is iets misgegaan met het ophalen van het bestand");
            }
        }
    };

    const onSubmit = () => {
        setSubmitted(true);
        setError();

        // Validate required fields
        let validation = validateData(formFields, values, true);

        let errors = [];

        if (values.locationId === undefined || values.locationId === null || !values.locationId > 0) {
            errors.push("Selecteer een locatie");
        }

        if (errors.length > 0) {
            let formError = errors.join(", ");
            setError(formError);
        }

        if (validation.status !== 'success' || errors.length > 0) {
            setErrors(validation.errors);
            setSubmitted(false);
            return;
        }

        setValues({});
        downloadFile();
    }

    const content = () => {

        let formIsSubmitted = isSubmitted;
        let formValues = values;

        if(isSubmitted === true) {
            formIsSubmitted = false;
            formValues = {}
        }

        return (<div className="text-component">
            <FormFields
                formFields={formFields}
                setValues={setInputValues}
                setErrors={setInputErrors}
                formValues={formValues}
                formErrors={errors}
                isSubmitted={formIsSubmitted}
            />

            <FormInputSubmit handleSubmit={onSubmit} submitLabel="Download excel" />

        </div>
        )
    }

    return (
        <>
            <Message type="success" message={message} />
            <Message type="error" message={error} />

            <ContentWrapper type='outline-box' content={content()} />
            <a href={window.location.href} className="hidden" ref={ref} >&nbsp;</a>
        </>
    );
}