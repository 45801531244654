import React from "react";
import { AppRentalsActions } from "../../app/Rentals/Actions";
////import { Identity } from "../../components/Identity";
import { PageTopbar } from "../../components/Page/Topbar";
import { PageHeader } from "../../components/Page/Header";

export function RoutesRentalsActions({ title, parents, current }) {

    return (
        <div>

            <PageTopbar
                parents={parents}
                current={current}
            />

            {/* <Identity /> */}

            <PageHeader
                title={title}
                tabGroup="rentals-overview"
                parents={parents}
                current={current}
            />

            <AppRentalsActions
                parents={parents}
                current={current}
            />
        </div>
    );
}