import { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthWrapper } from "./components/Auth/Wrapper";
import { RoutesNotfound } from "./routes/Notfound";
import { mapRoutes, getRoutesLists, getBaseStructure } from "./app/helpers/auth";

function App() {

    const [protectedRoutes, setProtectedRoutes] = useState();
    const [publicRoutes, setPublicRoutes] = useState();

    let routesTree  = JSON.parse(localStorage.getItem("routes-tree"));
    let mainMenu    = JSON.parse(localStorage.getItem("main-menu"));

    // Get basestructre - if undefined
    if ((mainMenu === null || routesTree === null)) {
        getBaseStructure();
    }

    if (publicRoutes === undefined || protectedRoutes === undefined) {
        setProtectedRoutes(getRoutesLists('protected'));
        setPublicRoutes(getRoutesLists('public'));
        return <></>
    }

    return (
        <Router>
            <div className="outer-wrapper">
                <div className="inner-wrapper">
                    <Routes>
                        {/* Auth: authentication routes do not need to be authenticated themselves */}
                        {mapRoutes(publicRoutes)}

                        {/* Routes that need authentication */}
                        {protectedRoutes !== undefined && protectedRoutes !== null &&
                            <Route element={<AuthWrapper />}>
                                {mapRoutes(protectedRoutes)}
                            </Route>
                        }

                        {/* Fallback page (page not found, with or without authentication token) */}
                        <Route path="*" element={<RoutesNotfound />} />

                    </Routes>
                </div>
                <div className="footer">Versie: {process.env.REACT_APP_VERSION}</div>
            </div>
        </Router >
    );
}

export default App;