import React, { useEffect, useState } from "react";
import { ValidateResponse, getCurrentPage, setCurrentPage } from "../../helpers/global";
import { fetchDepotCases } from "../../helpers/articles";
import { List } from "../../../components/List";
import { ListPagination } from "../../../components/List/Pagination";
import { Message } from "../../../components/Message";

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function AppArticlesDepotCases({ current, setFilterFields, filterValues, identifier }) {

    let pageSize = 10; // default values ergens globaal opslaan?

    const [cases, setCases] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [loadFilters, setLoadFilters] = useState();
    const [pageNr, setPageNr] = useState(getCurrentPage(current));
    const [totalCount, setTotalCount] = useState(0);
    const [error, setError] = useState();
    const [filters, setFilters] = useState(JSON.parse(localStorage.getItem("filters" + identifier)));
    const [filterString, setFilterString] = useState("{}");

    if (JSON.stringify(filterValues) !== filterString) {
        setFilterString(JSON.stringify(filterValues));
        setIsLoaded(false);
        setFilters(filterValues);
        setCurrentPage(1, current, setPageNr);
    }

    if (pageNr !== getCurrentPage(current)) {
        setPageNr(getCurrentPage(current));
    }

    const handlePagination = (page) => {
        setCurrentPage(page, current, setPageNr);
    }

    let listColumns = [
        { key: "name", type: "link", path: "details/:depotCaseId", hideView: "large" },
        { key: "name", label: "Opdracht", type: "text", hideView: 'small' },
        { key: "formType", label: "Type opdracht", type: "text" },
        { key: "createDate", label: "Aanmaakdatum", type: "date" }
    ];

    if (current === 'depot-archive') {
        listColumns.push({ key: "handleDate", label: "Verwerkingsdatum", type: "date" });
    }
    listColumns.push({ key: "depotCaseId", type: "link", path: "details/:depotCaseId", hideView: "small" });

    const optionsTypes = [
        { label: "In depot (ophalen)", value: "TRANSPORT-IN" },
        { label: "Leveren uit depot", value: "TRANSPORT-OUT" },
        { label: "Direct in depot", value: "DIRECT-IN" },
        { label: "Direct uit depot", value: "DIRECT-OUT" },
        { label: "Locatiewijziging", value: "TRANSPORT-TRANSFER" }
    ]

    // The filters are set here and send to parent onloaded
    const filterFields = {
        'categoryId': { type: 'categories', label: "Artikelcategorie", options: { output: "depot", category: "categoryId", article: "articleId" } },
        'formType': { type: 'select', label: "Type opdracht", options: optionsTypes },
    }

    useEffect(() => {

        async function fetchData() {

            const callData = {
                filters: {
                    tabType: current,
                    // sort: sort,
                    limitOffset: pageSize * pageNr - (pageSize - 1),
                    limitRows: pageSize,
                    ...filters
                }
            }

            await fetchDepotCases(callData).then((res) => {

                const response = ValidateResponse(res.data, 'cases');
                if (response.status === 'success') {
                    setTotalCount(response.totalCount ?? 0);
                    setCases(response.cases);
                    setIsLoaded(true);

                    if (loadFilters !== current && response.totalCount > 0) {
                        setFilterFields(filterFields);
                        setLoadFilters(current);
                    }
                }
                else {
                    setError(response.message);
                }
            }).catch((error) => {
                const response = ValidateResponse(error);
                setError(response.message);
            });


            

        }
        fetchData();
    }, [current, pageNr, filters, filterString, pageSize]);

    if (error !== undefined) {
        return (<Message type="error" message={error} />);
    }
    if (isLoaded === false) {
        return (<Message type="loading" />);
    }
    if (cases === undefined || cases === null || Object.keys(cases).length === 0) {
        return (<Message type="noData" />);
    }

    return (
        <>
            <List
                columns={listColumns}
                data={cases}
                identifier={identifier}
            />

            <div className={'spacer'}></div>

            <ListPagination
                currentPage={pageNr}
                setCurrentPage={handlePagination}
                totalCount={totalCount}
                pageSize={pageSize}
            />
            <div style={{ height: '30px' }}></div>
        </>
    );
}