import React from "react";
////import { Identity } from "../../components/Identity";
import { PageTopbar } from "../../components/Page/Topbar";
import { PageHeader } from "../../components/Page/Header";
import { AppManagementOverviews } from "../../app/Management/Overviews";

export function RoutesManagementOverviews({ title, parents, current }) {

    // let identifier = (parents + current).replaceAll('/', '-');

    return (
        <div>
            <PageTopbar
                parents={parents}
                current={current}
            />

            {/* <Identity /> */}

            <PageHeader
                title={title}
                tabGroup="management"
                parents={parents}
                current={current}
            />

            <AppManagementOverviews
                current={current}
            />
        </div>
    );
}