import React from "react";
import { PageTopbar } from "../components/Page/Topbar";
import { AppHome } from "../app/Home";

export function RoutesHome({ parents, current }) {

    return (
        <>
            <PageTopbar
                parents={parents}
                current={current}
            />
            <AppHome />
        </>
    );
}