import React, { useEffect, useState } from "react";
import { ValidateResponse, fetchLocations, fetchDepartments } from "../../../app/helpers/global";
import { Message } from "../../Message";
import { FormRender } from "../Render";

export function FormInputLocation({
    setValues, // required
    fieldValue = {},  // data object {locationId:.., departmentId:.., room:..} optional
    fieldLabel = { locationId: "Locatie", departmentId: "Afdeling", room: "Kamernr." },  // optional
    identifier, // optional
    fields = { 
        locationId: "locationId", 
        departmentId: "departmentId", 
        room: "room",
        parentId:0,
    },  // choose the fields to show on location & pass the names. 
        // parentId 1 fetches all entities form current organisation parent 0 fetches ALL customer entitites, main + locations
}) {

    const [error, setError] = useState();
    const [locations, setLocations] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const parentId = fields.parentId ?? 1;

    const locationKey = fields.locationId ?? null;
    const departmentKey = fields.departmentId ?? null;
    const roomKey = fields.room ?? null;

    const updateLocation = (locationId) => {

        if (fields.departmentId !== undefined) {
            fetchDepartments(locationId).then((res) => {

                const response = ValidateResponse(res.data, 'departments');

                if (response.status === 'success') {
                    setDepartments(response.departments);
                }
                else {
                    setError(response.message);
                }
            })
        }
    }

    useEffect(() => {

        fetchLocations(parentId).then((res) => {

            const response = ValidateResponse(res.data, 'locations');

            if (response.status === 'success') {
                setLocations(response.locations);
                if(fieldValue[locationKey] !== undefined && fieldValue[locationKey] !== null) {
                    updateLocation(fieldValue[locationKey]);
                }
                setIsLoaded(true);
            }
            else {
                setError(response.message);
            }
        }).catch((error) => {
            const response = ValidateResponse(error, 'status');
            setError(response.message);
        });

    }, []);

    const handleValues = (name, value) => {

        if (fields.locationId !== undefined && name === fields.locationId) {
            if (value !== null && value > 0) {
                updateLocation(value);
            }
            else if (departments.length === 0) {
                setDepartments([]);
                if (fieldValue[departmentKey] !== undefined && fieldValue[departmentKey] !== null && fields.departmentId !== undefined) {
                    setDepartments([]);
                    setValues(null, fields.departmentId, identifier);
                }
                if (fieldValue[roomKey] !== undefined && fieldValue[roomKey] !== null && fields.room !== undefined) {
                    setValues(null, fields.room, identifier);
                }
            }
        }

        setValues(name, value, identifier);
    }

    if (error !== undefined) {
        return (<Message type="error" message={error} />);
    }
    if (isLoaded === false) {
        return (<Message type="loading" />);
    }
    if (locations === undefined || locations === null || Object.keys(locations).length === 0) {
        return (<Message type="noData" />);
    }

    return (
        <>
            {locations.length > 0 &&
                <FormRender
                    setValues={handleValues}
                    fieldType="select"
                    fieldName={fields.locationId}
                    fieldValue={fieldValue[locationKey] ?? ""}
                    fieldLabel={fieldLabel.locationId}
                    identifier={identifier}
                    fieldOptions={locations}
                />
            }

            {fieldValue[locationKey] !== undefined 
                && fieldValue[locationKey] !== null 
                && departments.length > 0 
                && fields.departmentId !== undefined 
                &&
                <div className="form-fieldset">
                    <div className={"fieldset-element col-9"}>
                        <FormRender
                            setValues={handleValues}
                            fieldType="select"
                            fieldName={fields.departmentId}
                            fieldValue={fieldValue[departmentKey] ?? ""}
                            fieldLabel={fieldLabel.departmentId + " / " + fieldLabel.room}
                            identifier={identifier}
                            fieldOptions={departments}
                        />
                    </div>
                    <div className={"fieldset-element col-3"}>
                        <FormRender
                            setValues={handleValues}
                            fieldType="text"
                            fieldName={fields.room}
                            fieldValue={fieldValue[roomKey] ?? ""}
                            fieldLabel={String.fromCharCode(160)}
                            identifier={identifier}
                        />
                    </div>
                </div>
            }
        </>
    )
}