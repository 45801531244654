import React from "react";
import { Message } from "../../components/Message";
import { Link } from "react-router-dom";
import { ContentWrapper } from "../../components/Content/Wrapper";
import { validateRoute } from "../helpers/auth";

export function AppManagementOverviews() {
    const content = () => {
        return (
            <div className="tiles__wrapper">
                {validateRoute('/management/overviews/rental') === true &&
                    <div className="tiles__column">
                        <Link to={"/management/overviews/rental"} className={"tile tile__scannen active"}>
                            <span className="icon"></span>
                            <span className="tile__title">
                                Verhuur
                            </span>
                        </Link>
                    </div>
                }
                {validateRoute('/management/overviews/repairs') === true &&
                    <div className="tiles__column">
                        <Link to={"/management/overviews/repairs"} className={"tile tile__inspections"}>
                            <span className="icon"></span>
                            <span className="tile__title">
                                Reparaties
                            </span>
                        </Link>
                    </div>
                }
                {validateRoute('/management/overviews/depot') === true &&
                    <div className="tiles__column">
                        <Link to={"/management/overviews/depot"} className={"tile tile__registreren"}>
                            <span className="icon"></span>
                            <span className="tile__title">
                                Depot opdrachten
                            </span>
                        </Link>
                    </div>
                }
                                {validateRoute('/management/overviews/productslocation') === true &&
                    <div className="tiles__column">
                        <Link to={"/management/overviews/productslocation"} className={"tile tile__registreren"}>
                            <span className="icon"></span>
                            <span className="tile__title">
                                Hulpmiddelen op locatie
                            </span>
                        </Link>
                    </div>
                }
                {validateRoute('/management/overviews/productsdepot') === true &&
                    <div className="tiles__column">
                        <Link to={"/management/overviews/productsdepot"} className={"tile tile__registreren"}>
                            <span className="icon"></span>
                            <span className="tile__title">
                                Hulpmiddelen in depot
                            </span>
                        </Link>
                    </div>
                }
            </div>
        )
    }

    return (
        <ContentWrapper type="tiles-secondary" content={content()} />
    );
}