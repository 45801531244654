import React, { useState } from "react";
import { useParams } from "react-router-dom";
//import { Identity } from "../../../components/Identity";
import { PageTopbar } from "../../../components/Page/Topbar";
import { PageHeader } from "../../../components/Page/Header";
import { AppArticlesProductInspections } from "../../../app/Articles/Product/Inspections";
import { getPageTitle } from "../../../app/helpers/global";

export function RoutesArticlesProductInspections({ parents, current }) {

    let identifier = (parents + current).replaceAll('/', '-');
    let group = parents.indexOf('articles/depot')===-1 ? 'location' : 'depot';

    const { productId } = useParams();
    const [filterFields, setFilterFields] = useState([]);
    const [filterValues, setFilterValues] = useState(JSON.parse(localStorage.getItem("filters" + identifier)) ?? {});
    const [refresh, setRefresh] = useState(false);
    const [title, setTitle] = useState(null);

    if (title === null) {
        getPageTitle('product', productId, setTitle);
    }

    const resetFilterValues = (values) => {
        setFilterValues(values);
        setRefresh(refresh === false);
    }

    return (
        <div>

            <PageTopbar
                parents={parents}
                current={current}
            />

            {/* <Identity /> */}

            <PageHeader
                title={title}
                tabGroup={"product-details-" + group}
                variables={{ productId: productId }}
                parents={parents}
                current={current}
                filterFields={filterFields}
                setFilterValues={resetFilterValues}
                identifier={identifier}
            />

            <AppArticlesProductInspections
                productId={productId}
                current={current}
                filterValues={filterValues}
                setFilterFields={setFilterFields}
                identifier={identifier}
            />
        </div>
    );
}