import React from "react";
import { Link } from "react-router-dom";
import { ContentWrapper } from "../../../components/Content/Wrapper";
import { validateRoute } from "../../helpers/auth";

export function AppRentalsCasesActions({ rentalCaseId, parents }) {

    const content = () => {
        return (
            <div className="tiles__wrapper">
                {validateRoute(parents + '/retour/:rentalCaseId') === true &&
                    <div className="tiles__column">
                        <Link to={"../retour/" + rentalCaseId} className={"tile tile__retour"}>
                            <span className="icon"></span>
                            <span className="tile__title">
                                Retour<br />melden
                            </span>
                        </Link>
                    </div>
                }
                {validateRoute(parents + '/repair/:rentalCaseId') === true &&
                    <div className="tiles__column">
                        <Link to={"../repair/" + rentalCaseId} className={"tile tile__reparatie"}>
                            <span className="icon"></span>
                            <span className="tile__title">
                                Reparatie<br />melden
                            </span>
                        </Link>
                    </div>
                }
            </div>
        )
    }

    return (
        <>
            <ContentWrapper type="tiles-secondary" content={content()} />
        </>
    );
}