import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { AppArticlesProductDetailsDocuments } from "../../../app/Articles/Product/Details/Documents";
import { PageHeader } from "../../../components/Page/Header";
import { PageTopbar } from "../../../components/Page/Topbar";
import { getPageTitle } from "../../../app/helpers/global";
/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function RoutesArticlesProductDocuments({ parents, current }) {

    let group = parents.indexOf('articles/depot') === -1 ? 'location' : 'depot';
    const { productId } = useParams();
    const [title, setTitle] = useState(null);

    if (title === null) {
        getPageTitle('product', productId, setTitle);
    }

    return (
        <div>
            <PageTopbar
                parents={parents}
                current={current}
            />
            <PageHeader title={title}
                parents={parents}
                current={current}
                tabGroup={"product-details-" + group}
                variables={{ productId: productId }} />

            <AppArticlesProductDetailsDocuments
                productId={productId} />
        </div>
    )
}