import React from "react";
import { AppArticlesAdd } from "../../app/Articles/Add";
////import { Identity } from "../../components/Identity";
import { PageTopbar } from "../../components/Page/Topbar";
import { Message } from "../../components/Message";
import { ContentWrapper} from "../../components/Content/Wrapper";

export function RoutesArticlesAdd({ parents, current }) {

    return (
        <div>

            <PageTopbar
                parents={parents}
                current={current}
            />

            {/* <Identity /> */}

            <ContentWrapper type="centred" content={<Message type='state' />} />
            

            <AppArticlesAdd
                parents={parents}
                current={current}
            />
        </div>
    );
}