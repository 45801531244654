/**
 * To set title in localstorage: localStorage.setItem("pageTitle:product", value)
 */
import { Filters } from "../Filters";

export function PageTitle({ title, filterFields, setFilterValues, identifier }) {

    return <>
        <div className="components">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="text-component page-title">
                            <div className="row align-items-center justify-content-between">

                                <h3>{title}</h3>

                                {filterFields !== undefined && Object.keys(filterFields).length > 0 && (
                                    <Filters
                                        fields={filterFields}
                                        setFilterValues={setFilterValues}
                                        identifier={identifier}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}