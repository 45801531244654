import React, { useEffect, useState } from "react";
import { ValidateResponse, fetchDownload, getCurrentPage, setCurrentPage } from "../../helpers/global";
import { fetchDepotCases } from "../../helpers/management";
import { List } from "../../../components/List";
import { ListPagination } from "../../../components/List/Pagination";
import { Message } from "../../../components/Message";
import { returnValues, removeValue, validateData } from "../../../components/Form/Formfunctions";
import { FormFields } from "../../../components/Form/Fields";
import { FormInputSubmit } from "../../../components/Form/Input/Submit";
import { ContentWrapper } from "../../../components/Content/Wrapper";
import { ContentGenerateExcel } from "../../../components/Content/GenerateExcel";

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function AppManagementOverviewsDepot({ current, identifier, filterValues, setFilterFields }) {

    let pageSize = 10; // default values ergens globaal opslaan?

    const [cases, setCases] = useState();
    //const [contacts, setContacts] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [loadFilters, setLoadFilters] = useState(true);
    const [pageNr, setPageNr] = useState(getCurrentPage(current));
    const [totalCount, setTotalCount] = useState(0);
    const [error, setError] = useState();
    const [errors, setErrors] = useState();
    const [values, setValues] = useState({});
    const [filters, setFilters] = useState(JSON.parse(localStorage.getItem("filters" + identifier)) ?? {});
    const [filterString, setFilterString] = useState("{}");
    const [isSubmitted, setSubmitted] = useState(false);
    const [documentName, setDocumentName] = useState();

    const defineFilterFields = () => {
        let filterFields = {
            fromDate: {
                type: "date",
                label: 'Van datum',
                options: { min: "-125", max: null },
            },
            toDate: {
                type: "date",
                label: 'Tot datum',
                options: { min: "-125", max: null },
            },
        };

        setFilterFields(filterFields);

        return filterFields;
    }

    let formFields = {
        fromDate: {
            type: "date",
            label: 'Van datum',
            options: { min: "-125", max: null },
            value: values['deliverDate'],
        },
        toDate: {
            type: "date",
            label: 'Tot datum',
            options: { min: "-125", max: null },
            value: values['deliverDate'],
        }
    }

    const setInputValues = (key, value) => {

        // reset errors
        if (isSubmitted === true) {
            setSubmitted(false);
        }
        if (errors) {
            setErrors(removeValue(errors, key, null));
        }

        setValues(returnValues(values, key, value, null));
        setFilters(returnValues(values, key, value, null));
        localStorage.setItem("filters" + identifier, JSON.stringify(returnValues(values, key, value, null)));
    }

    const setInputErrors = (key, value, identifier = null) => {

        // add or remove error
        let newErrors = errors;
        if (value !== undefined && value !== null) {
            newErrors = returnValues(errors, key, value, identifier);
        }
        else {
            newErrors = removeValue(errors, key, identifier);
        }
        setErrors(newErrors);
    }

    if (pageNr !== getCurrentPage(current)) {
        setPageNr(getCurrentPage(current));
    }


    let listColumns = [
        // { key: "repairNumber", type: "link", path: "product/details/:productId", hideView: "large" },
        { key: "depotCaseId", label: "Opdracht nr.", type: "text" },
        { key: "handleDate", label: "Datum afgehandeld", type: "date" },
        { key: "customerName", label: "Aanvraag vanuit", type: "date" },
        { key: "articleGroup", label: "Artikelgroep", type: "text" },
        { key: "serialNumber", label: "Serienummer", type: "text" },
        { key: "formType", label: "Type opdracht", type: "text" },
        { key: "pickupAddress", label: "Ophaaladres", type: "text" },
        { key: "dropoffAddress", label: "Afleveradres", type: "text" },
    ];

    const fetchData = (page) => {

        setTotalCount();
        setCases();
        setSubmitted(true);
        var filterData = filters;
        const callData = {
            tabType: current,
            filters: {
                limitOffset: pageSize * page - (pageSize - 1),
                limitRows: pageSize,
                ...filterData
            }
        }

        fetchDepotCases(callData).then((res) => {

            const response = ValidateResponse(res.data, 'cases');

            if (response.status === 'success') {
                if (response.documentName !== undefined) {
                    setDocumentName(response.documentName);
                }
                if (response.totalCount !== undefined) {
                    setTotalCount(response.totalCount);
                    if (loadFilters !== current) {
                        setLoadFilters(current);
                        defineFilterFields();
                    }

                }
                setCases(response.cases);
                setIsLoaded(true);
            }
            else {
                setError(response.message);
            }

        }).catch((error) => {
            const response = ValidateResponse(error);
            setError(response.message);
        });
    }

    if (JSON.stringify(filterValues) !== filterString) {
        fetchData(1, filterValues);
        setFilterString(JSON.stringify(filterValues));
        setIsLoaded(false);
        setFilters(filterValues);
        setCurrentPage(1, current, setPageNr);
    }

    if (error !== undefined) {
        return (<Message type="error" message={error} />);
    }
    // if (isLoaded === false) {
    //     return (<Message type="loading" />);
    // }
    // if (cases === undefined || cases === null || Object.keys(cases).length === 0) {
    //     return (<Message type="noData" />);
    // }

    const onSubmit = () => {

        setCurrentPage(1, current, setPageNr);
        setSubmitted(true);
        let validation = validateData(formFields, values, true);

        if (validation.status !== 'success' || (!values.formDate && !values.toDate && !values.type)) {
            setErrors(validation.errors);
            setSubmitted(false);
            return;
        }

        fetchData(1);
    }

    const handlePagination = (page) => {
        setCurrentPage(page, current, setPageNr);
        fetchData(page);
    }

    const content = () => {
        return (<div className="text-component">
            <FormFields
                formFields={formFields}
                setValues={setInputValues}
                setErrors={setInputErrors}
                formValues={values}
                formErrors={errors}
                //identifier = null
                isSubmitted={isSubmitted}
            />

            <FormInputSubmit handleSubmit={onSubmit} submitLabel="Zoeken" />

        </div>
        )
    }

    const excelbutton = () => {
        return <ContentGenerateExcel
            documentType={'depot'}
            filters={filters}
            display={'button-download'}
            fileName={documentName}
            mimeType={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
            label={'Download excel'}
        />
    }

    return (
        <>
            {isSubmitted == false &&
                <ContentWrapper type='outline-box' content={content()} />
            }

            {cases !== 'NULL' && cases !== undefined &&
                <>
                    {Object.keys(cases).length > 0 &&
                        <ContentWrapper type='page-component' className="floating-button" content={excelbutton()} />
                    }
                    <List
                        columns={listColumns}
                        data={cases}
                        identifier="customers"
                    />

                    <div className={'spacer'}></div>
                    <ListPagination
                        className="pagination-bar"
                        currentPage={pageNr}
                        totalCount={totalCount}
                        pageSize={pageSize}
                        setCurrentPage={handlePagination}
                    />
                    <div className={'spacer'}></div>
                </>
            }
            {/* <List
                columns={listColumns}
                data={cases}
                identifier={identifier}
            />

            <div className={'spacer'}></div>

            <ListPagination
                currentPage={pageNr}
                setCurrentPage={handlePagination}
                totalCount={totalCount}
                pageSize={pageSize}
            />
            <div className={'spacer'}></div> */}
        </>
    );
}