import React, { useState} from "react";
import { useParams } from "react-router-dom";
//import { Identity } from "../../../../components/Identity";
import { PageTopbar } from "../../../../components/Page/Topbar";
import { PageHeader } from "../../../../components/Page/Header";
import { AppArticlesProductActionsRepair } from "../../../../app/Articles/Product/Actions/Repair";
import { getPageTitle} from "../../../../app/helpers/global";

export function RoutesRentalsCasesActionsRepair({ parents, current }) {

    const { rentalCaseId } = useParams();
    const [ title, setTitle] = useState(null);

    let group = (parents).replaceAll('/', '-');
    if (title === null) {
        getPageTitle('rental', rentalCaseId, setTitle);
    }

    return (
        <div>
            <PageTopbar
                parents={parents}
                current={current}
            />

            {/* <Identity /> */}

            <PageHeader
                title={title}
                parents={parents}
                current={current}
                variables={{ rentalCaseId: rentalCaseId }}
                tabGroup={group}
            />

            <AppArticlesProductActionsRepair
                parents={parents}
                redirectUri={ "/" + parents + "/details/" + rentalCaseId }
                cancelUri={ "/" + parents + "/details/" + rentalCaseId }
                requestType="REPAIR-RENTAL"
                sourceId={rentalCaseId}
            />
        </div>
    );
}