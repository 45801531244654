import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fetchRentalsForRetour, processRentalRetour } from "../../../helpers/rentals";
import { ValidateResponse } from "../../../helpers/global";
import { checkRequest,fetchContacts } from "../../../helpers/customer";
import { Message } from "../../../../components/Message";
import { List } from "../../../../components/List";
import { ContentWrapper } from "../../../../components/Content/Wrapper";
import { FormInputSubmit } from "../../../../components/Form/Input/Submit";
import { FormFields } from "../../../../components/Form/Fields";

export function AppRentalsCasesActionsRetour({ parents, rentalCaseId, identifier }) {

    const navigate = useNavigate();
    const [state, setState] = useState(1);
    const [isLoaded, setIsLoaded] = useState(false);
    const [loadRetour, setLoadRetour] = useState(true);
    const [error, setError] = useState();
    const [formErrors, setFormErrors] = useState({});
    const [values, setValues] = useState({});
    const [rentalsList, setRentalsList] = useState();
    const [columns, setColumns] = useState([]);
    const [contacts, setContacts] = useState();

        useEffect(() => {

            fetchContacts().then((res) => {
            const response = ValidateResponse(res.data, 'contacts');
                if (response.status === 'success') {
                setContacts(response.contacts);
                response.contacts.forEach((contact) => {
                        if(contact.selected !== undefined){
                            let newValues = {...values,                   
                                contactId: contact.value
                            };
                            setValues(newValues)
                        }
                    });
                }
            });      
    }, []);

    const formFields = {
        retourDate: { type: "date", label: "Gewenste ophaaldatum", options:{min:null, max:"+1"}},
        contactId: {type: "select", label: "Contactpersoon", options: contacts},
        comments: { type: "textarea", label: "Opmerkingen" }
    }
    
    const handleInput = (name, value, identifier, allCases) => {

        let newValues = values;

        if (name.indexOf('rentalCaseId__') !== -1) {

            let cases = allCases ?? rentalsList;
            let selectedCases = values.selectedCases ?? [];
            let checkedCase = name.replace('rentalCaseId__', '');

            if (value === true && selectedCases.includes(checkedCase) === false) {
                selectedCases.push(checkedCase);
            }
            else if (value === false && selectedCases.includes(checkedCase) === true) {
                var index = selectedCases.indexOf(checkedCase);
                if (index !== -1) {
                    selectedCases.splice(index, 1);
                }
            }

            cases.forEach((rental, i) => {
                if (rental.rentalCaseId === checkedCase) {
                    cases[i].isChecked = value;
                }
            });

            setRentalsList(cases);
            newValues.selectedCases = selectedCases;
        }
        else {
            newValues[name] = value;
        }

        setValues(newValues);
        return;
    }

    const handleFormErrors = (name, value) => {
        let newErrors= formErrors;
        newErrors[name] = value;
        setFormErrors(newErrors);
    }

    useEffect(() => {

        checkRequest(rentalCaseId + '/RENTAL-STOP').then((res) => {
            const response = ValidateResponse(res.data, 'status');
            if (response.status !== 'success') {
                setError(response.message);
            }
        })
    }, [rentalCaseId]);

    useEffect(() => {

        const listColumns = [
            { key: 'rentalCaseId', type: 'checkbox', valueKey: 'isChecked' },
            { key: 'rentalCaseId', type: 'text', label: "Dossiernummer" },
            { key: 'articleName', type: 'text', label: "Naam hulpmiddel" },
            { key: 'serialNumber', type: 'text', label: "Serienummer" },
            { key: 'startDate', type: 'date', label: "Startdatum" },
            { key: 'location', type: 'text', label: "Locatie" },
            { key: 'departmentRoom', type: 'text', label: "Afdeling/kamernr." },
            { key: 'endUser', type: 'text', label: "Eindgebruiker" }
        ]
        
        if (rentalsList === undefined) {
            fetchRentalsForRetour({ rentalCaseId: rentalCaseId }).then((res) => {

                const response = ValidateResponse(res.data, 'cases');
                if (response.status === 'success') {
                    setRentalsList(response.cases);
                    
                    setIsLoaded(true);
                    setColumns(listColumns);
                }
                else {
                    setError(response.message);
                }
            })
        }

    }, [rentalCaseId, rentalsList]);

    if (isLoaded === true && loadRetour !== identifier) {
        handleInput("rentalCaseId__" + rentalCaseId, true, identifier, rentalsList);
        setLoadRetour(identifier);
    }

    const handleRetour = () => {

        if (state === 1 && values.selectedCases !== undefined && values.selectedCases.length > 0) {
            let cases = [];
            rentalsList.forEach((rental) => {
                if (values.selectedCases.includes(rental.rentalCaseId)) {
                    cases.push(rental);
                }
            });

            let listColumns = columns;
            delete listColumns[0];
            setRentalsList(cases);
            setColumns(listColumns);
            setState(2);
        }
        else if (state === 2) {

            const formData = {
                'rentalCases': values.selectedCases,
                'retourDate': values.retourDate ?? null,
                'comments': values.comments ?? null,
                'contactId': values.contactId
            }

            processRentalRetour(formData).then((res) => {

                const response = ValidateResponse(res.data, 'status');
                if (response.status === 'success') {
                    navigate("/" + parents + "/details/" + rentalCaseId, {
                        replace: true,
                        state: { message: response.message, type:response.status }
                    });
                }
                else {
                    setError(response.message);
                }
            }).catch((error) => {
                const response = ValidateResponse(error);
                setError(response.message);
            })
        }
    }


    if (error !== undefined) {
        return (<Message type="error" message={error} />);
    }
    if (isLoaded === false) {
        return (<Message type="loading" />);
    }

    const content = () => {

        let redirectUri = "/" + parents + "/details/" + rentalCaseId;
        let submitLabel = "Volgende stap";
        if (state === 2) {
            redirectUri = "/" + parents + "/actions/" + rentalCaseId;
            submitLabel = "Opslaan";
        }

        if (rentalsList !== undefined && rentalsList.length > 0) {
            return (
                <>
                    <h3>Verhuurdossier(s) retour melden</h3>
                    {state === 2 &&
                        <FormFields
                            formFields={formFields}
                            setValues={handleInput}
                            setErrors={handleFormErrors}
                            formValues={values}
                            formErrors={formErrors}
                        />
                    }
                    <List
                        columns={columns}
                        data={rentalsList}
                        identifier={identifier}
                        handleAction={handleInput}
                    />
                    <FormInputSubmit
                        handleSubmit={handleRetour}
                        redirectUri={redirectUri}
                        submitLabel={submitLabel}
                    />
                </>
            );
        }
        else {
            return (<Message type="noData" />);
        }

    }

    return (
        <ContentWrapper
            type="outline-box"
            content={content()}
            className="outline-box--form"
        />
    );
}