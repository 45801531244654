import React from "react";
import { useParams } from "react-router-dom";
import { PageTopbar } from "../../components/Page/Topbar";
import { AppAuthRegister } from "../../app/Auth/Register";

export function RoutesAuthRegister({ parents, current }) {

    let { token } = useParams();

    return (
        <>
            <PageTopbar
                parents={parents}
                current={current}
            />
   
            <AppAuthRegister token={token}/>
        </>
    );
}