import React, { useState, useEffect } from "react";
import { fetchLocations, fetchDepartments, ValidateResponse } from "../../../helpers/global";
import { checkRequest, fetchContacts } from "../../../helpers/customer";
import { getProductDetails, actionProductRelocate } from "../../../helpers/articles";
import { useNavigate } from "react-router-dom";
import { FormFields } from "../../../../components/Form/Fields";
import { FormInputSubmit } from "../../../../components/Form/Input/Submit";
import { Message } from "../../../../components/Message";
import { ContentWrapper } from "../../../../components/Content/Wrapper";

export function AppArticlesProductActionsRelocate({ productId, parents }) {

    const navigate = useNavigate();
    const [state, setState] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [error, setError] = useState();
    const [currentLocation, setCurrentLocation] = useState();
   // const [currentDepartment, setCurrentDepartment] = useState();
    const [locations, setLocations] = useState();
    const [departments, setDepartments] = useState();
    const [handler, setHandler] = useState(null);
    const [formErrors, setFormErrors] = useState({});
    const [values, setValues] = useState({});
    const [contacts, setContacts] = useState();

    const updateLocation = (locationId) => {

        setCurrentLocation(locationId);
        fetchDepartments(locationId).then((res) => {
            const response = ValidateResponse(res.data, 'departments');
            if (response.status === 'success') {
                setDepartments(response.departments);
            }
            else {
                setError(response.message);
            }
        })
    }

    useEffect(() => {

             fetchContacts().then((res) => {
                const response = ValidateResponse(res.data, 'contacts');
                if (response.status === 'success') {
                    setContacts(response.contacts);
                    response.contacts.forEach((contact) => {
                        if(contact.selected !== undefined){
                            let newValues = {...values,                   
                                contactId: contact.value
                            };
                            setValues(newValues)
                        }
                    })
                }
            });      
    }, []);

    useEffect(() => {

        checkRequest(productId + '/PRODUCT-LOCATION-UPDATE').then((res) => {
            const response = ValidateResponse(res.data, 'status');
            if (response.status !== 'success') {
                setError(response.message);
            }
            setIsChecked(true);
        })
    }, [productId, navigate]);

    useEffect(() => {

        if (currentLocation === undefined || isLoaded === false) {

            getProductDetails(productId).then((res) => {
                const response = ValidateResponse(res.data, 'details');
                if (response.status === 'success') {

                    setState(response.details);
                    if (currentLocation === undefined) {
                        let responseValues =  {
                            ...values,
                            productId: productId,
                            locationId: response.details.ownerEntityId,
                            departmentId: response.details.departmentId,
                            room: response.details.room,
                            handler: handler
                        }
                        setValues(responseValues);
                        setIsLoaded(true);
                        updateLocation(response.details.ownerEntityId);
                    }
                }
                else {
                    setError(response.message);
                }
            })
        }
    }, [productId, handler,currentLocation, isLoaded])

    useEffect(() => {
        fetchLocations().then((res) => {

            const response = ValidateResponse(res.data, 'locations');

            if (response.status === 'success') {
                setLocations(response.locations);
            }
            else {
                setError(response.message);
            }
        })
    }, [setError])


    const handleInput = (name, value, identifier) => {

        let newValues = values;

        if (identifier !== undefined && identifier !== null) {
            if (newValues[identifier] === undefined) {
                newValues[identifier] = {}
            }
            newValues[identifier][name] = value;
        }
        else {
            newValues[name] = value;
        }

        // Custom form behaviour
        if (name === "locationId") {
            updateLocation(value);
            newValues.room = null;
            newValues.departmentId = null;
        }

        if (name === 'handler') {
            setHandler(value);
        }

        setValues(newValues);
        return;
    }

    const sendValues = () => {

        //let data = values;
        let requiredValues = ['locationId', 'handler', 'contactId'];

        // Validate required fields
        let validation = {};

        requiredValues.forEach((requiredField) => {
            if (values[requiredField] === undefined || values[requiredField] === null || values[requiredField].length === 0) {
                validation[requiredField] = 'Verplicht veld';
            }
        });

        if (Object.keys(validation).length !== 0) {
            setFormErrors(validation);
            return;
        }

        let postData = {
            productId: values.productId,
            toLocation: values.locationId,
            toDepartment: values.departmentId,
            toRoom: values.room,
            handler: values.handler,
            comment: values.comment ?? null,
            deliverDate: values.deliverDate ?? null,
            contactId: values.contactId
        }

        actionProductRelocate(postData).then((res) => {

            const response = ValidateResponse(res.data, 'status');

            if (response.status === 'success') {
                navigate("/" + parents.replace("actions", "details/") + productId, {
                    replace: true,
                    state: { message: response.message, type:response.status }
                });
            }
            else {
                setError(response.message);
            }
        }).catch((error) => {
            const response = ValidateResponse(error);
            setError(response.message);
        })

    }

    if (error !== undefined) {
        return (<Message type="error" message={error} />);
    }
    if (isChecked === false || isLoaded === false || locations === undefined || departments === undefined) {
        return (<Message type="loading" />);
    }
    if (state === undefined || state === null || Object.keys(state).length === 0) {
        return (<Message type="noData" />);
    }

    const handlerOptions = [
        { label: "Van der Mark", value: "vdm" },
        { label: "Intern", value: "intern" }
    ]

    const fields = {
        'locationId': { type: 'select', label: "Locatie", options: locations },
        'departmentRoom': {
            type: "fieldset",
            options: {
                'departmentId': { type: 'select', label: "Afdeling", options: departments, className: 'col-8' },
                'room': { type: 'text', label: "Kamer", className: 'col-4' }
            }
        },
        // 'location':{type:'location'},
        'handler': { type: "radio", label: "Uitvoeren door", options: handlerOptions },
        'contactId': {type: "select", label: "Contactpersoon", options: contacts}
    }

    const fieldsHandlerVdm = {
        'comment': { type: 'textarea', label: "Opmerkingen" },
        'deliverDate': { type: 'date', label: "Gewenste uitvoerdatum", options:{min:null, max:"+1"} },
    }

    const content = () => {
        return (
            <>
                <h3 className="box-header">Locatie wijzigen / verplaatsen</h3>

                <FormFields
                    formFields={fields}
                    setValues={handleInput}
                    setErrors={setFormErrors}
                    formValues={values}
                    formErrors={formErrors}
                />

                {handler === 'vdm' &&
                    <FormFields
                        formFields={fieldsHandlerVdm}
                        setValues={handleInput}
                        setErrors={setFormErrors}
                        formValues={values}
                        formErrors={formErrors}
                    />
                }

                <FormInputSubmit
                    handleSubmit={sendValues}
                    cancelUri={"/" + parents + "/" + productId}
                />
            </>
        )
    }

    return (
        <ContentWrapper
            type="outline-box"
            content={content()}
            className="outline-box--form"
        />
    );
}