import React, { useEffect, useState } from "react";
import {
    ValidateResponse,
    fetchArticleCategories,
    fetchCategoryArticles
} from "../../../app/helpers/global";
import { Message } from "../../Message";


export function FormInputCategories({
    fieldName,
    fieldValue = {}, // dataArray
    setValues,
    fields = {category:"categoryId", article:"articleId"}, 
    output, // catalog | rental | intern | extern | depot
    //identifier,
}) {

    if (fields === undefined 
        || fields === null
        || fields.category === undefined) {
        alert('Fields are not set!');
    }

    // to refactor!
    if (output === 'extern') {
        output = 'depot'
    }

    const showArticles = fields.article !== undefined ? true : false;

    // Load categories list
    const [isLoaded, setIsLoaded] = useState(false);
    const [categories, setCategories] = useState();
    const [articles, setArticles] = useState([]);
    const [dataValue, setDataValue] = useState(fieldValue.categoryId ?? 0);
    const [dataArray, setDataArray] = useState(fieldValue.categoryParents ?? []);
    const [error, setError] = useState();

    if (Object.keys(fieldValue).length === 0 && dataValue > 0 && dataArray.length > 0) {
        setDataValue(0);
        setDataArray([]);
        setArticles([]);
    }

    useEffect(() => {
        fetchArticleCategories({ articleType: output }).then((res) => {

            let response = ValidateResponse(res.data, 'categories');
            if (response.status === 'error') {
                setError(response.message);
            }
            else {
                const result = []
                for (var i in response.categories) {
                    result[i] = response.categories[i];
                }

                setCategories(result);
                setIsLoaded(true);
            }
        })

        if (output === undefined 
            || (output !== 'catalog' 
                && output !== 'intern' 
                && output !== 'rental' 
                && output !== 'depot'
            )) {
            alert('output type undefined' + output);
            return;
        }

    }, [output])

    if (error !== undefined && error !== null) {
        return <Message type='error' message={error} />
    }
    if (isLoaded === false) {
        return null;
    }
    const findOptions = (optionParentId) => {
        let options = [];
        categories.forEach((subItems) => {

            if (subItems[0] !== undefined) {
                if (parseInt(subItems[0].parentId) === parseInt(optionParentId)) {
                    subItems.forEach((option) => {
                        options.push(option);
                    });
                }
            }
        });

        return options;
    }

    // Set options
    let optionParentId;

    if (dataValue === 0) {
        optionParentId = categories[0][0].parentId;
    }
    else {
        dataArray.forEach((parentItem) => {
            optionParentId = parentItem.id; // need the last id form the parents list
        });
    }

    let options = findOptions(optionParentId);

    const getArticles = (categoryId) => {

        if (fields.article === undefined || fields.article === null) {
            return;
        }
        let resultData = [];

        if (parseInt(categoryId) > 0) { // && findOptions(categoryId).length === 0)

            let articleType = (output === 'depot' ? 'DEPOT' : 'RENTAL');

            fetchCategoryArticles({ categoryId: categoryId, articleType: articleType }).then((res) => {

                let response = ValidateResponse(res.data, 'articles');
                if (response.status === 'error') {
                    setError(response.message);
                }
                else {
                    response.articles.forEach((article) => {
                        resultData.push(article);
                    });
                    setArticles(resultData);
                }
            })
        }
    }

    const handleReset = (id) => {

        let newId = 0;
        let resetArray = [];
        let level = dataArray.length;

        dataArray.forEach((item, i) => {
            if (item.id === id) {
                level = i;
            }
            else if (i < level) {
                resetArray.push(item);
                newId = item.id
            }
        });

        setDataValue(newId);
        setDataArray(resetArray);
        setArticles([]); // clear
        if (showArticles === false) {
            getArticles(newId);
        }
        setValues(fieldName, { categoryId: newId, categoryParents: resetArray, articleId: null, article: null });
        setValues(fields.category, newId);
    }

    const handleChange = (event) => {
        let value = event.target.value;
        if (value !== "") {
            setCategoryValues(value, options);
        }
    }

    const handleSelectCategory = (categoryId) => {
        setValues(fieldName, { categoryId: dataValue, categoryParents: dataArray, selected: categoryId });
        setValues(fields.category, categoryId);
        return;
    }

    const setCategoryValues = (categoryId, itemList) => {

        let selectedItem;

        Object.values(itemList).forEach((item) => {

            if (item.id !== undefined && parseInt(item.id) === parseInt(categoryId)) {
                selectedItem = item;
            }
            else if (item.categoryId !== undefined && parseInt(item.categoryId) === parseInt(categoryId)) {
                selectedItem = item;
            }
        });

        if (selectedItem === undefined) {
            alert('Categorie niet gevonden');
            return;
        }

        let newDataArray = dataArray;

        newDataArray.push({
            id: categoryId,
            title: selectedItem.title,
            priceDisplay: selectedItem.priceDisplay,
            isRentable: selectedItem.isRentable
        });

        setDataArray(newDataArray);
        setDataValue(categoryId);
        setArticles([]); // clear
        getArticles(categoryId);
        setValues(fieldName, { categoryId: categoryId, categoryParents: newDataArray, article: null, articleId: null });
        setValues(fields.category, categoryId);

        if (output === 'catalog' && findOptions(categoryId).length === 0) {
            handleSelectCategory(categoryId);
        }
    }

    const setArticle = (event) => {

        let articleId = event.target.value;
        if (articleId === "") {
            setValues(fields.article, null);
        }

        let name = event.target[event.target.selectedIndex].getAttribute('data-name');

        if (articleId !== null) {
            setValues(fieldName, {
                categoryId: dataValue,
                categoryParents: dataArray,
                articleId: articleId,
                article: {
                    articleId: articleId,
                    name: name,
                }
            });
            setValues(fields.article, articleId);
        }
        return;
    }

    const renderDropdown = () => {

        if (options.length === 0) {
            return null;
        }
        let labelContent = dataValue === 0 ? "categorie" : "subcategorie";

        return (
            <>
                <div>
                    <select onChange={handleChange}>
                        <option  data-name={'select-' + labelContent} key={'select-' + labelContent} value={""}>- Selecteer {labelContent} -</option>
                        {Object.values(options).map(o => <option key={o.categoryId} value={o.categoryId}>{o.title}</option>)}
                    </select>
                </div>
                <div style={{ clear: 'both', height: '12px' }}></div>
            </>
        )
    }

    const renderArticles = () => {

        if (showArticles === true && articles.length !== 0) { // && findOptions(dataValue).length === 0
            return <>
                <div>
                    <select onChange={setArticle}>
                        <option  data-name={'select-article'} key={'select-article'} value={""}>- Selecteer Artikel -</option>
                        {Object.values(articles).map(a => <option data-name={a.name} key={a.id} value={a.id}>{a.name}</option>)}
                    </select>
                </div>
                <div style={{ clear: 'both', height: '12px' }}></div>
            </>
        }
    }

    return (
        <>
            <div>
                {Object.values(dataArray).map((parent, i) =>
                    <span className="categories-trail-wrapper" key={"categories-trail-item-" + parent.id}>
                        <button className="categories-trail" onClick={() => handleReset(parent.id)}>
                            {parent.title}
                        </button>
                        {parent.isRentable === true && (i + 1) === Object.values(dataArray).length &&
                            <button className="categories-trail selectable" onClick={() => handleSelectCategory(parent.id)}>
                                {fields.selectLabel ?? "Selecteren"}
                            </button>
                        }
                    </span>

                )}
            </div>
            <div>
                <div style={{ clear: 'both', height: "5px" }}></div>

                {renderDropdown()}

                {renderArticles()}

            </div>
        </>
    )
}