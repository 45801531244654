import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { PageTopbar } from "../../../components/Page/Topbar";
import { PageHeader } from "../../../components/Page/Header";
import { AppArticlesProductActions } from "../../../app/Articles/Product/Actions";
import { getPageTitle} from "../../../app/helpers/global";

export function RoutesArticlesProductActions({ parents, current }) {

    let identifier = (parents+current).replaceAll('/', '-');
    let group = parents.indexOf('articles/depot')===-1 ? 'location' : 'depot';

    const { productId } = useParams();
    const [ title, setTitle] = useState(null);

    if (title === null) {
        getPageTitle('product', productId, setTitle);
    }

    return (
        <>
            <PageTopbar
                parents={parents}
                current={current}
            />
 
            <PageHeader
                title={title}
                parents={parents}
                current={current}
                tabGroup={"product-details-" + group}
                variables={{productId:productId}}
                identifier={identifier}
            />

            <AppArticlesProductActions
                productId={productId}
                parents={parents}
                identifier={identifier}
            />
        </>
    );
}