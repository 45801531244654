import React, { useEffect, useState, useCallback } from "react";
import { ValidateResponse, fetchProductDocuments } from "../../../helpers/global";
import { IMAGE_URL } from "../../../helpers/api";
import { getProductStatus } from "../../../helpers/articles";
import { Message } from "../../../../components/Message";
import { ContentWrapper } from "../../../../components/Content/Wrapper";
import { ContentDownload } from "../../../../components/Content/Download";

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function AppArticlesProductDetailsDocuments({ productId }) {

    const user = JSON.parse(localStorage.getItem('auth'))
    const [fileList, setFileList] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState();

    const fetchData = useCallback(async () => {

        await fetchProductDocuments(productId)
            .then((res) => {

                const response = ValidateResponse(res.data, 'documents');

                if (response.status === 'error') {
                    setError(response.message, response.status);
                }
                else {

                    if (response.documents.length === 0) {
                        setIsLoaded(true);
                    }
                    response.documents.forEach((file) => {
                        if (file.type === 'IMAGE') {
                            var xhr = new XMLHttpRequest();
                            xhr.responseType = 'blob'; //so you can access the response like a normal URL
                            xhr.onreadystatechange = function () {
                                if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                                    file.src = URL.createObjectURL(xhr.response);
                                    setFileList((fileList) => [...fileList, file]);
                                    setIsLoaded(true);
                                }
                            };
                            xhr.open('GET', IMAGE_URL + '?guid=' + file.guid, true);
                            xhr.setRequestHeader('X-Auth-Token', user.token);
                            xhr.send();
                        }
                        else {
                            setFileList((fileList) => [...fileList, file]);
                            setIsLoaded(true);
                        }

                    });
                }
            }).catch((error) => {
                const response = ValidateResponse(error, 'status');
                setError(response.message, response.status);
            })

    }, [productId])

    useEffect(() => {
        if (isLoaded === false) {
            fetchData();
        }
    }, [fetchData, setFileList])

    const showGallery = () => {
        return (
            <div className="tiles__wrapper">
                {fileList.map((file) => (
                    <div key={"product-photo-" + file.guid} className="tiles__photo">
                        <div className="tile tile__photo">
                            {file.type === 'IMAGE' &&
                                <img src={file.src} alt={file.fileName} />
                            }
                            {file.type !== 'IMAGE' &&
                                <div className="document-download">
                                    <h3>{file.typeDisplay}</h3>
                                    <div>{file.description}</div>

                                    <ContentDownload 
                                        documentType="PRODUCT-DOCUMENT" 
                                        display={"tile-" + file.icon ?? "file"}
                                        documentId={file.documentId}
                                        fileName={file.fileName}
                                        mimeType={file.mimeType}
                                        label={file.fileName}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                ))}
            </div>
        );
    }
    let message = getProductStatus(productId) === 'REMOVED' ? "Huplmiddel is afgevoerd" : null;

    if (error !== undefined) {
        return (<Message type="error" message={error} />);
    }
    
    if (isLoaded === false || fileList.length == 0) {
        return (
            <>
                <Message type="warning" message={message} />
                <Message type="noData" />
            </>
        );
    }

    return (
        <>
            <div className="spacer" />

            <Message type="warning" message={message} />

            <ContentWrapper
                type="tiles-secondary"
                content={showGallery(fileList)}
            />
        </>
    )
}