import React, { useEffect, useState } from "react";
import { ValidateResponse, getCurrentPage, setCurrentPage } from "../helpers/global";
import { fetchInvoices, testFunction } from "../helpers/management";
import { List } from "../../components/List";
import {  ListPagination } from "../../components/List/Pagination";
import { Message } from "../../components/Message";

export function AppManagementInvoices({ current, setFilterFields, filterValues = {}, viewType, typeId, identifier }) {

    let pageSize = 10; // default values ergens globaal opslaan?

    const [invoices, setInvoices] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [loadFilters, setLoadFilters] = useState(true);
    const [pageNr, setPageNr] = useState(getCurrentPage(current));
    const [totalCount, setTotalCount] = useState(0);
    const [error, setError] = useState();
    const [filters, setFilters] = useState(JSON.parse(localStorage.getItem("filters" + identifier)) ?? null);
    const [filterString, setFilterString] = useState("{}");

    const defineFilterFields = () => {

        if (viewType === 'rental-case') {
            return; // no filters on rental-case view
        }

        let optionsPaymentStatus = [
            {value:"OPEN", label:"Te betalen"},
            {value:"HANDLED", label:"Voldaan"},
        ]

        let optionsInvoiceType = [
            {value:"0", label:"Debet"},
            {value:"1", label:"Credit"}
        ]

        let optionsOriginType = [
            {value:"SALE", label:"Verkoop"},
            {value:"RENTAL", label:"Verhuur (periodieke verzamelfactuur)"},
            {value:"Depot", label:"Depot"},
        ]

        let filterFields = {
            invoiceEntity: { type: 'location', options:{locationId:"locationId"}, label:{locationId:"Financieel verantwoordelijke"}},
            shipmentEntity: { type: 'location', options:{locationId:"shipmentLocationId"}, label:{locationId:"Afleverlocatie"}},
            paymentStatus: {type: 'select', label:"Betaalstatus", options:optionsPaymentStatus},
            isCredit: { type: 'select', label: "Type factuur", options:optionsInvoiceType},
            originType: { type: 'select', label: "Oorsprong", options:optionsOriginType},
        };

        setFilterFields(filterFields);

        return filterFields;
    }

    if (JSON.stringify(filterValues) !== filterString) {
        setFilterString(JSON.stringify(filterValues));
        setIsLoaded(false);
        setFilters(filterValues);
        setCurrentPage(1, current, setPageNr);
    }

    if (pageNr !== getCurrentPage(current)) {
        setPageNr(getCurrentPage(current));
    }

    const handlePagination = (page) => {
        setCurrentPage(page, current, setPageNr);
    }

    let listColumns = [
        { key: "title", type: "title", hideView: "large"}, //0
        { key: "invoiceNumber", type: "text", label: "Factuurnr.", hideView: "small"},//1
        { key: "invoiceDate", type: "date", label: "Datum"},//2
        { key: "priceDisplay", type: "text", label: "Totaalbedrag"},//3
        { key: "paymentStatus", type: "text", label: "Betaalstatus"},//4
        { key: "originType", type: "text", label: "Oorsprong"},//5
        { key: "location", type: "text", label: "Locatie"},//6
        { key: "invoiceId", label: "Download factuur", type: "download", display:"icon-download", documentType: "invoice", fileName: "documentName"}
    ];

    if (viewType === 'rental-case') {
        delete listColumns[5];
        delete listColumns[6];
    }

    useEffect(() => {

        async function fetchData() {

            const callData = {
                tabType: current,
                viewType: viewType ?? null,
                typeId: typeId ?? null,
                filters: {
                    limitOffset: pageSize * pageNr - (pageSize - 1),
                    limitRows: pageSize,
                    ...filters
                }
            }

            await fetchInvoices(callData).then((res) => {

                const response = ValidateResponse(res.data, 'invoices');

                if (response.status === 'success') {

                    if (response.totalCount !== undefined) {
                        setTotalCount(response.totalCount);
                        
                        if (loadFilters !== current) {
                            defineFilterFields();
                            setLoadFilters(current);
                        }
                    }
                    setInvoices(response.invoices);
                    setIsLoaded(true);
                }
                else {
                    setError(response.message);
                }

            }).catch((error) => {
                const response = ValidateResponse(error);
                setError(response.message);
            });
        }
        fetchData();
    }, [current, pageNr, filters, pageSize, typeId,viewType]);

    if (error !== undefined) {
        return (<Message type="error" message={error} />);
    }
    if (isLoaded === false) {
        return (<Message type="loading" />);
    }
    if (invoices === undefined || invoices === null || Object.keys(invoices).length === 0) {
        return (<Message type="noData" />);
    }

    return (
        <>
            <List
                columns={listColumns}
                data={invoices}
                identifier={identifier}
            />

            <div className={'spacer'}></div>

            <ListPagination
                currentPage={pageNr}
                setCurrentPage={handlePagination}
                totalCount={totalCount}
                pageSize={pageSize}
            />
            <div className={'spacer'}></div>
        </>
    );
}