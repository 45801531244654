import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from 'react-select';
import { convertOptionsList, getOptionsValue } from "../Formfunctions";
/**
 * 
/**
 * Default form element parameters:
 * * fieldname (string) the name of the fields
 * * defaultValue (productId) sets the productid in the selectfield
 * * setValues (function) needs to be set in parent to transfer data values & errors
 * * isSubmitted (bool) to mark if the "submit" button is clicked, to raise errors on empty required fields
 * * required (bool) to mark if it's a required field in the parent funtion
 * 
 * Custom parameters:
 * * options (object) the options to show on the "productId" selectbox
 * 
 * @todo: if default value is set ... than "Bestaand serienummer" should be selected with the defaultvalue? (& resetted to defaultvalue if change inputoption)
 */
export function FormInputSerialOptions({
    fieldName = 'inputOption',
    fieldValue = null,
    setValues,
    isSubmitted = false,
    identifier = null,
    required = true,
    options = {} }) {
    
    const { control } = useForm();
    const [inputOption, setInputOption] = useState(null);
    const [serialNumber, setSerialNumber] = useState(null);
    const [productId, setProductId] = useState(fieldValue);

    const inputOptions = {
        productId: "Bestaand serienummer",
        serialNumber: "Nieuw serienummer (serienummer is bekend)",
        serialUnknown: "Automatisch aanmaken (serienummer onbekend)"
    }

    const handleChange = (event) => {
        let name = event.target.dataset.name;
        let value = event.target.value;

        handleValues(name, value);
    }

    const handleSelect = (option, element) => {
        let name = element.name;
        let value = option.value;
        handleValues(name, value);
    }

    const handleValues = (name, value) => {

        let returnValues = {};

        returnValues[name] = value;

        if (returnValues.errors === undefined) {
            returnValues.errors = {};
        }

        if (name === fieldName) {

            setInputOption(value);
            setSerialNumber(null);
            setProductId(fieldValue)

            if (value === 'serialUnknown') {
                delete returnValues.errors;
                returnValues.serialUnknown = true;
            }
            else {
                returnValues.errors[value] = "Verplicht veld";
            }
        }
        else if (name === "serialNumber" && value) {
            setSerialNumber(value);
            delete returnValues.errors;
        }
        else if (name === "productId" && value) {
            setProductId(value);
            delete returnValues.errors;
        }
        // setErrors(name, );
        setValues(name, value, identifier);
    }

    const getError = (checkField) => {
        let errorMessage = null;

        if (checkField === fieldName && isSubmitted === true && required === true && inputOption === null) {
            errorMessage = 'Verplicht veld';
        }

        if (checkField === 'serialNumber' && inputOption === checkField && isSubmitted === true && serialNumber === null) {
            errorMessage = 'Verplicht veld';
        }

        if (checkField === 'productId' && inputOption === checkField && isSubmitted === true && productId === null) {
            errorMessage = 'Verplicht veld';
        }

        if (errorMessage !== null) {
            return <span style={{ color: 'red' }}>{errorMessage}: </span>
        }
    }

    return (
        <>
            <div className="form__row">
                <div className="form__component">
                    <div className="form__label">
                        {getError(fieldName)}
                        Invoer serienummer {required && <span>*</span>}
                    </div>
                </div>
            </div>

            <div className="form__row form__row--fieldset form__row--serialoptions">

                {Object.keys(inputOptions).map((optionKey) =>
                    <div className="form__component form__component--auto" key={"serial-input-" + identifier + optionKey}>
                        <div className="form__element">
                            <div className="form__input">
                                <input
                                    type="radio"
                                    id={"serial-input-option-" + identifier + optionKey}
                                    value={optionKey}
                                    data-name={fieldName}
                                    checked={inputOption === optionKey}
                                    onChange={handleChange}
                                />
                                <label htmlFor={"serial-input-option-" + identifier + optionKey}>{inputOptions[optionKey]}</label>
                            </div>
                        </div>
                    </div>
                )}

            </div>
            {inputOption === 'productId' &&
                <div className="form__row col-12" id="input-option-productId">
                    <div className="form__component">
                        <label htmlFor="" className="form__label">
                            {getError(inputOption)}
                            Kies serienummer
                        </label>

                        <div className="form__element">
                            {/*  Select veld met interne filter ... */}
                            <Controller
                                control={control}
                                name="productId"
                                render={({ field: { onChange, value, ref, name } }) => (
                                    <Select
                                        defaultValue={getOptionsValue(fieldValue, options)}
                                        placeholder={"Maak een keuze"}
                                        name="productId"
                                        options={convertOptionsList(options)}
                                        onChange={handleSelect}
                                    />)}
                            />
                        </div>
                    </div>
                </div>
            }

            {inputOption === 'serialNumber' &&
                <div className="form__row col-12" id="input-option-productId">
                    <div className="form__component">
                        <label htmlFor="" className="form__label">
                            {getError(inputOption)}
                            Nieuw serienummer invoeren
                        </label>
                        <div className="form__element">
                            <input type="text" onChange={handleChange} data-name="serialNumber" />
                        </div>
                    </div>
                </div>
            }
        </>
    )
}