import React from "react";
import { Link } from "react-router-dom";
import { ContentWrapper } from "../../components/Content/Wrapper";
import { validateRoute } from "../helpers/auth";

export function AppArticlesActions() {

    const content = () => {
        return (
            <div className="tiles__wrapper">
                {validateRoute('/articles/actions/scan') === true &&
                    <div className="tiles__column">
                        <Link to={"/articles/actions/scan"} className={"tile tile__scannen active"}>
                            <span className="icon"></span>
                            <span className="tile__title">
                                Scannen
                            </span>
                        </Link>
                    </div>
                }
                {validateRoute('/articles/inspections') === true &&
                    <div className="tiles__column">
                        <Link to={"/articles/inspections"} className={"tile tile__inspections"}>
                            <span className="icon"></span>
                            <span className="tile__title">
                                Keuringen
                            </span>
                        </Link>
                    </div>
                }
                {validateRoute('/articles/repairs') === true &&
                    <div className="tiles__column">
                        <Link to={"/articles/repairs"} className={"tile tile__reparatie"}>
                            <span className="icon"></span>
                            <span className="tile__title">
                                Reparaties
                            </span>
                        </Link>
                    </div>
                }
                {validateRoute('/articles/add') === true &&
                    <div className="tiles__column">
                        <Link to={"/articles/add"} className={"tile tile__registreren"}>
                            <span className="icon"></span>
                            <span className="tile__title">
                                Hulpmiddel<br />registreren
                            </span>
                        </Link>
                    </div>
                }
            </div>
        )
    }

    return (
        <ContentWrapper type="tiles-secondary" content={content()} />
    );

}