import React from "react";
import { PageTopbar } from "../components/Page/Topbar";
import { AppScan } from "../app/Scan";

export function RoutesScan({ parents, current }) {

    console.log(current);
    console.log(parents);

    let returnPath = parents !== undefined ? "/" + parents : "/";

    return (
        <>
            <PageTopbar
                parents={parents}
                current={current}
            />
            <AppScan
                method="search"
                returnPath={returnPath}
            />
        </>
    );
}