import React from 'react';
import { usePagination, DOTS } from './usePagination';

export const  ListPagination = props => {

    const {
        currentPage,
        setCurrentPage,
        totalCount,
        pageSize,
        siblingCount = 1,
    } = props;
    
    if (currentPage < 1) {
        currentPage = 1;
    }
    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    let dotCount = 0;

    const onNext = () => {
        setCurrentPage(currentPage + 1);
    };

    const onPrevious = () => {
        setCurrentPage(currentPage - 1);
    };

    let lastPage = paginationRange[paginationRange.length - 1];
    if (lastPage < 1) {
        lastPage = 1;
    }


    if (totalCount <= pageSize) {
        return (<div></div>)
    }

    return (
        <ul className='component pagination-container'>
            {/* Left navigation arrow */}
            {currentPage !== 1 &&
                <li className='pagination-item' onClick={onPrevious}>
                    <div className="arrow left" />
                </li>
            }

            {paginationRange.map(pageNumber => {

                // If the pageItem is a DOT, render the DOTS unicode character
                if (pageNumber === DOTS) {
                    dotCount++;
                    return <li key={pageNumber + dotCount} className="pagination-item dots">&#8230;</li>;
                }

                // Render our Page Pills
                return (
                    <div key={pageNumber}>
                        {pageNumber !== currentPage &&
                            <li className='pagination-item' onClick={() => setCurrentPage(pageNumber)}>
                                {pageNumber}
                            </li>}
                        {pageNumber === currentPage &&
                            <li className='pagination-item selected' onClick={() => setCurrentPage(pageNumber)}>
                                {pageNumber}
                            </li>}
                    </div>
                );
            })}
            {/*  Right Navigation arrow */}
            {currentPage !== lastPage &&
                <li className='pagination-item' onClick={onNext}>
                    <div className="arrow right" />
                </li>
            }
        </ul>
    );
};

export default  ListPagination; // ????

