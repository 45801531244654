
import axios from 'axios';
import { API_URL, getApiHeaders } from "./api.js"

const checkRequest = requestData => axios.get(
    `${API_URL}/customer/requests/checkpermission/` + requestData, getApiHeaders()
);
const fetchContacts = () => axios.get(
    `${API_URL}/global/contacts`, getApiHeaders()
);

export{
    checkRequest,
    fetchContacts,
}