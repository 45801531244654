export function ContentWrapper({ content, type, className = "", handleFunction, params = {} }) {

    return <>

        {content !== undefined && content !== null &&
            <div className="container">
                <div className="row">
                    <div className="col-12">

                        {type === undefined &&
                            <>
                                {alert('Wrapper type is required!')}
                            </>
                        }
                        {type === 'page-component' &&

                            <div className="components">
                                {content}
                            </div>
                        }
                        {type === 'title' &&
                            <div className="components">
                                <div className={"col-auto text-component " + className}>
                                    <h3> {content} </h3>
                                </div>
                            </div>
                        }
                        {(type === 'title-button' || type === 'buttons') &&

                            <div className="components">
                                {/* <div className="container"> */}
                                <div className="row justify-content-center">
                                    <div className="col-12" style={{paddingRight:"0px"}}>
                                        
                                            {type === 'title-button' &&
                                                <div className="row align-items-center justify-content-between">
                                                    <h3>{content}</h3>
                                                    <button
                                                        type={params.type ?? "submit"}
                                                        onClick={handleFunction ?? null}
                                                        className={"button button--primary " + (params.type ?? "submit")}>
                                                        {params.label ?? ""}
                                                    </button>
                                                    </div>
                                            }
                                            {type === 'buttons' && params.buttons !== undefined &&
                                                <div className="buttons-row-wrapper">
                                                    {params.buttons.map((button, i) => (
                                                        <button
                                                            key = {button.key ?? "button-" + i}
                                                            data-value={button.value ?? null}
                                                            type={button.type ?? "submit"}
                                                            onClick={handleFunction ?? null}
                                                            className={"button button--primary " + (button.type ?? "submit")}>
                                                            {button.label ?? ""}
                                                        </button>
                                                    ))}
                                                </div>
                                            }

                                        
                                    </div>
                                </div>
                                {/* </div> */}
                            </div>
                        }
                        {type === 'outline-box' &&
                            <div className="components">
                                <div className={"outline-box " + className}>
                                    {content}
                                </div>
                            </div>
                        }

                        {type === 'tiles-secondary' &&
                            <div className="tiles-details">
                                <div className="tiles__wrapper">
                                    {content}
                                </div>
                            </div>
                        }

                        {type === 'centred' &&
                            <div className="container centred">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-8 col-lg-7">
                                        <div className="component">
                                            {content}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        }
    </>
}