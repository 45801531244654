import React, { useState, useEffect } from "react";
import { fetchRentalDetails } from "../../helpers/rentals";
import { ValidateResponse } from "../../helpers/global";
import { Message } from "../../../components/Message";
import { Details } from "../../../components/Details";

export function AppRentalsCasesDetails({ rentalCaseId, setTitle }) {

    const [state, setState] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {
        fetchRentalDetails(rentalCaseId).then((res) => {

            const response = ValidateResponse(res.data, 'details');

            if (response.status === 'error') {
                setError(response.message, response.status);
            }
            else {
                setState(response.details);
                setIsLoaded(true);
                setTitle(response.details.title);
            }
        }).catch((error) => {
            const response = ValidateResponse(error);
            setError(response.message);
        });

    }, [rentalCaseId, setTitle]);

    if (error !== undefined) {
        return (<Message type="error" message={error} />);
    }
    if (isLoaded === false) {
        return (<Message type="loading" />);
    }
    if (state === undefined || state === null || Object.keys(state).length === 0) {
        return (<Message type="noData" />);
    }

    let columns = [
        // West
        { content: "Verhuurdossier", type: "title", icon: "rent", gcId: "west" },
        { key: "articleName", label: "Artikelnaam", type: "text", gcId: "west" },
        { key: "serialNumber", label: "Serienummer", type: "text", gcId: "west" },
        { key: "barcode", label: "Qr code", type: "text", gcId: "west" },
        { key: "rentalCaseId", label: "Dossiernr.", type: "text", gcId: "west" },
        { key: "statusDisplay", label: "Status", type: "text", gcId: "west" },
        { key: "priceDisplay", label: "Huurprijs", type: "text", gcId: "west" },
        { key: "startDate", label: "Startdatum", type: "date", gcId: "west" },
        { key: "endDate", label: "Einddatum", type: "date", gcId: "west" },
        { key: "rentalExtensions", label: "Meegeleverde artikelen", type: "text", gcId: "west" },

        // East
        { content: "Contact-en locatiegegevens", type: "title", icon: "user", gcId: "east" },
        { key: "endUser", label: "Eindgebruiker", type: "text", gcId: "east" },
        { key: "birthDate", label: "Geboortedatum", type: "date", gcId: "east" },
        { key: "departmentRoom", label: "Afdeling/kamernr.", type: "text", gcId: "east" },
        { key: "generalUse", label: "Algemeen gebruik", type: "text", gcId: "east" },
        { key: "contactUser", label: "Contactpersoon", type: "text", gcId: "east" },
        { key: "contactPhone", label: "Contact telefoonnr.", type: "text", gcId: "east" },
        { key: "contactEmail", label: "Emailadres", type: "text", gcId: "east" },
        { key: "rentalAddress", label: "Verhuuradres", type: "box", gcId: "east" },
        { key: "invoiceAddress", label: "Factuuradres", type: "box", gcId: "east" },
    ];

    // remove if empty
    let removeIfEmpty = [
        'rentalExtensions',
        'endUser',
        'birthDate',
        'departmentRoom',
        'genaralUse',
        'contactUser',
        'contactPhone',
        'contactEmail'
    ];

    columns.forEach((col, i) => {
        if (removeIfEmpty.includes(col.key) && (state[col.key] === null || state[col.key] === "")) {
            delete columns[i];
        }

        if (state.status === 'ACTIVE' && col.key === 'endDate') {
            delete columns[i];
        }
        if (state.status === 'WAITING' && (col.key === 'startDate' || col.key === 'endDate')) {
            delete columns[i];
        }

    });

    return (
        <>
            <Details
                columns={columns}
                data={state}
                identifier="details"
            />
        </>
    );
}