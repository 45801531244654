import React, { useEffect, useState } from "react";
import { ValidateResponse, setCurrentPage, getCurrentPage } from "../../helpers/global";
import { fetchProductRepairs, getProductStatus } from "../../helpers/articles";
import { List } from "../../../components/List";
import { ListPagination } from "../../../components/List/Pagination";
import { Message } from "../../../components/Message";

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function AppArticlesProductRepairs({ productId, current, setFilterFields, filterValues, identifier }) {

    let pageSize = 10; // default values ergens globaal opslaan?

    const [repairs, setRepairs] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [loadFilters, setLoadFilters] = useState(true);
    const [pageNr, setPageNr] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [error, setError] = useState();
    const [filters, setFilters] = useState(JSON.parse(localStorage.getItem("filters" + identifier)));
    const [filterString, setFilterString] = useState();

    if (JSON.stringify(filterValues) !== filterString) {
        setFilterString(JSON.stringify(filterValues));
        setIsLoaded(false);
        setFilters(filterValues);
        setCurrentPage(1, current, setPageNr);
    }

    if (pageNr !== getCurrentPage(current)) {
        setPageNr(getCurrentPage(current));
    }

    const handlePagination = (page) => {
        setCurrentPage(page, current, setPageNr);
    }

    let listColumns = [
        { key: "repairNumber", label: "Reparatienummer", type: "text" },
        { key: "statusLabel", label: "Status", type: "text" },
        { key: "createDate", label: "Aanmaakdatum", type: "date" },
        { key: "repairDate", label: "Reparatiedatum", type: "date" },
        { key: "repairer", label: "Reparateur", type: "text" },
        { key: "repairNumber", label: "Reparatiebon", type: "download", display: "icon", fileName: "documentName", documentType: "repair" }
    ];

    // Afgevoerd zie je niet... wel afgerond
    const optionsConclusion = [
        { value: "PROCESSING", label: "In behandeling" }, // new/processing/waiting
        { value: "FIXED", label: "Afgerond" }, // new/processing/waiting
    ]

    // // The filters are set here and send to parent onloaded
    let filterFields = {
        'repairId': { type: 'text', label: "Repartienummer" },
        'conclusion': { type: 'select', label: "Reparatiestatus", options: optionsConclusion },
    }

    useEffect(() => {

        const callData = {
            filters: {
                productId: productId,
                limitOffset: pageSize * pageNr - (pageSize - 1),
                limitRows: pageSize,
                ...filters
            }
        }

        fetchProductRepairs(callData).then((res) => {

            const response = ValidateResponse(res.data, 'repairs');

            if (response.status === 'error') {
                setError(response.message, response.status);
            }
            else {
                if (response.totalCount !== undefined) {
                    setTotalCount(response.totalCount);

                    if (loadFilters !== current) {
                        setFilterFields(filterFields);
                        setLoadFilters(current);
                    }
                }
                setRepairs(response.repairs);
                setIsLoaded(true);
            }
        }).catch((error) => {
            const response = ValidateResponse(error);
            setError(response.message);
        });

    }, [productId, filters, setFilterFields, pageNr, pageSize]);


    let message = getProductStatus(productId) === 'REMOVED' ? "Huplmiddel is afgevoerd" : null;

    if (error !== undefined) {
        return (<Message type="error" message={error} />);
    }
    if (isLoaded === false) {
        return (<Message type="loading" />);
    }
    if (repairs === undefined || repairs === null || Object.keys(repairs).length === 0) {
        return (
            <>
                <Message type="warning" message={message} />
                <Message type="noData" />
            </>
        );
    }

    return (
        <>
            <Message type="warning" message={message} />

            <List
                columns={listColumns}
                data={repairs}
                identifier={identifier}
            />

            <div className={'spacer'}></div>

            <ListPagination
                currentPage={pageNr}
                totalCount={totalCount}
                pageSize={pageSize}
                setCurrentPage={handlePagination}
            />
            <div style={{ height: '30px' }}></div>
        </>
    );
}