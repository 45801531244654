/**
 * @see helpers/global
 */
import axios from 'axios';
import { API_URL, getApiHeaders } from "./api.js"


// GET
const fetchRentalDetails = rentalCaseId => {
    return axios.get(`${API_URL}/customer/rentals/details/` + rentalCaseId, getApiHeaders())
}
const fetchRentalCatalog = () => axios.get(
    `${API_URL}/customer/rentals/catalog`, getApiHeaders()
);

const fetchRentalCategories = () => axios.get(
    `${API_URL}/customer/rentals/categories`, getApiHeaders()
);

// POST 
const fetchRentalCases = postData => {
    return axios.post(`${API_URL}/customer/rentals/cases`, postData, getApiHeaders())
}
const fetchRentalDocuments = postData => {
    return axios.post(`${API_URL}/customer/rentals/documents`, postData, getApiHeaders())
}
const fetchRentalsForRetour = formData => {
    return axios.post(`${API_URL}/customer/rentals/request/retour/cases`, formData, getApiHeaders())
}
// PUT / PATCH
const processRentalRetour = formData => {
    return axios.put(`${API_URL}/customer/rentals/request/retour/process`, formData, getApiHeaders())
}
const processRentalRequest = formData => {
    return axios.put(`${API_URL}/customer/rentals/request/article`, formData, getApiHeaders())
}


export{
    fetchRentalCases,
    fetchRentalDocuments,
    fetchRentalsForRetour,
    processRentalRetour,
    processRentalRequest,
    fetchRentalDetails,
    fetchRentalCatalog,
    fetchRentalCategories
}