import React, { useEffect, useState } from "react";
import { ValidateResponse, getCurrentPage, setCurrentPage }  from "../../../helpers/global";
import { fetchProductLog, getProductStatus } from "../../../helpers/articles";
import { Message } from "../../../../components/Message";
import { List } from "../../../../components/List";
import { ListPagination } from "../../../../components/List/Pagination";
/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function AppArticlesProductDetailsLog({ productId, current, identifier }) {

    let pageSize = 10; // default values ergens globaal opslaan?

    //const { status } = useParams();
    const [list, setList] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [pageNr, setPageNr] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [error, setError] = useState();

    if (pageNr !== getCurrentPage(current)) {
        setPageNr(getCurrentPage(current));
    }

    const handlePagination = (page) => {
        setCurrentPage(page, current, setPageNr);
    }

    let listColumns = [
        { key: "createDate", label: "Datum", type: "date" },
        { key: "message", label: "Actie", type: "text" },
        { key: "specification", label: "Details", type: "text" },
        { key: "name", label: "Door", type: "text" },
    ];

    useEffect(() => {

        const callData = {
            productId: productId,
            filters: {
                limitOffset: pageSize * pageNr - (pageSize - 1),
                limitRows: pageSize,
            }
        }

        fetchProductLog(callData).then((res) => {

            const response = ValidateResponse(res.data, 'log');

            if (response.status === 'error') {
                setError(response.message, response.status);
            }
            else {
                if (response.totalCount !== undefined) {
                    setTotalCount(response.totalCount);
                }
                setList(response.log);
                setIsLoaded(true);
            }
        }).catch((error) => {
            const response = ValidateResponse(error);
            setError(response.message);
        });

    }, [productId, pageNr, pageSize]);

    let message = getProductStatus(productId) === 'REMOVED' ? "Huplmiddel is afgevoerd" : null;

    if (error !== undefined) {
        return (<Message type="error" message={error} />);
    }
    if (isLoaded === false) {
        return (<Message type="loading" />);
    }
    if (list === undefined || list === null || Object.keys(list).length === 0) {
        return (
            <>
                <Message type="warning" message={message} />
                <Message type="noData" />
            </>
        );
    }

    return (
        <>
            <Message type="warning" message={message} />
            
            <List
                columns={listColumns}
                data={list}
                identifier={identifier}
            />

            <div className={'spacer'}></div>

            <ListPagination
                currentPage={pageNr}
                totalCount={totalCount}
                pageSize={pageSize}
                setCurrentPage={handlePagination}
            />
            <div style={{ height: '30px' }}></div>
        </>
    );
}