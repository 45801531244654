import React, { useState } from "react";
import { QrReader } from 'react-qr-reader';
import { Link, useNavigate } from "react-router-dom";
import { ValidateResponse } from "./helpers/global";
import { findQrCode, actionQrCodeAdd } from "./helpers/articles";
import { FormFields } from "../components/Form/Fields";
import { FormInputSubmit } from "../components/Form/Input/Submit";
import { Message } from "../components/Message";
import { validateRoute } from "./helpers/auth";

// method search|add
export function AppScan({
    method="search",
    productId,
    returnPath = "/",
    title
}) {
    const navigate = useNavigate();
    const [error, setError] = useState();
    const [values, setValues] = useState({});

    // Voor scan moet je toegang hebben tot artikelen of rental
    let rentalPermission = validateRoute('/rentals/active/actions/:rentalCaseId');
    let articlePermission = validateRoute('/articles/location/product/actions/:productId');

    if (error === undefined && rentalPermission + articlePermission === 0) {
        setError('U heeft geen rechten om gescande serienummers op te zoeken');
    }

    const handleInput = (key, value) => {
        let newValues = values;
        newValues[key] = value;
        setValues(newValues);
    }

    const handleErrors = (key, value) => {
        setError(value);
    }

    const handleSubmit = () => {

        if (values.barcode === undefined || !values.barcode) {
            setError('Voer een barcode in');
        }
        else {
            handleScan(values);
        }
    }

    const handleScan = (data) => {

        // MUST contain 10 numbers
        let barcode = null;

        if (data && data.text) {
            barcode = data.text;
        }

        if (data && data.barcode) {
            barcode = data.barcode;
        }

        if (barcode !== null) {

            // Add code to (depot) product
            if (method === "add") {
                if (productId === undefined) {
                    setError('Er is geen product gevonden om de QR code te koppelen');
                }
                else {
                    handleAddCode(barcode, productId);
                }
            }
            else {
                handleSearchCode(barcode);
            }
        }
    };

    /**
     * Find QR code and redirect to page
     */
    const handleSearchCode = (barcode) => {
        let callData = { 
            barcode: barcode, 
        }

        findQrCode(callData).then((res) => {

            const response = ValidateResponse(res.data, 'response');

            if (response.status === 'success') {

                let data = response.response;
                let rentalRedirect = rentalPermission > 0 ? "actions/" : "details/";
                let articleRedirect = articlePermission > 0 ? "actions/" : "details/";

                if (data.type === 'rental' && data.rentalcaseId !==undefined) {

                    navigate("/rentals/active/" + rentalRedirect + data.rentalcaseId);
                }
                else if (data.type === 'depot-out' && data.productId !==undefined) {
                    navigate("/articles/location/product/" + articleRedirect + data.productId);
                }
                else if (data.type === 'depot-in' && data.productId !==undefined) {
                    navigate("/articles/depot/product/" + articleRedirect + data.productId);
                }
                else {
                    setError('Hulpmiddel niet gevonden');
                }


            }
            else {
                setError(response.message);
            }

        }).catch((error) => {
            const response = ValidateResponse(error);
            console.log(error);
            setError(response.message);
        })
    }
    
    const handleAddCode = (barcode, productId) => {
        if (barcode === null) {
            setError('Scan een code of vul deze handmatig in');
            return;
        }

        let postData = { 
            barcode: barcode, 
            productId: productId 
        }

        actionQrCodeAdd(postData).then((res) => {

            const response = ValidateResponse(res.data, 'status');

            if (response.status === 'success') {
                navigate(returnPath.replace(":productId", productId), {
                    replace: true,
                    state: { message: response.message ?? "Qr code is succesvol gekoppeld" }
                });
            }
            else {
                setError(response.message);
            }
        }).catch((error) => {
            const response = ValidateResponse(error);
            setError(response.message);
        })
    }

    const content = () => {
        return (
            <>
                <div className="component">
                    <h3>{title}</h3>
                </div>

                <div className="outline-boxes">
                    <div className="component w-100">
                        {method === 'search' &&

                            <div className="outline-box outline-box--form">

                                <FormFields
                                    formFields={{ barcode: {type: "text", label: "handmatig zoeken op code/serienummer" }}}
                                    setValues={handleInput}
                                    setErrors={handleErrors}
                                />

                                <FormInputSubmit
                                    handleSubmit={handleSubmit}
                                    submitLabel="Opzoeken"
                                    cancelUri={returnPath}
                                />

                            </div>
                        }
                    </div>
                </div>

                <Message message={error} type={'inline-error'} />

                <div className="outline-box outline-box--details" data-match>
                    <div className="text-component">
                        <div className="form__component form__component--auto">
                            <div className="form__element">
                                <div className="form__scan">
                                    <QrReader
                                        onResult={handleScan}
                                        showViewFinder={true}
                                        style={{ width: '100%' }}
                                        constraints={{ facingMode: 'environment' }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {method === 'add' &&
                    <div className="outline-box outline-box--form">
                        <Link to={returnPath} className={"button button--outline"}>
                            <span>Annuleren</span>
                        </Link>
                    </div>
                }
            </>
        );
    }

    return (
        <div className="main">
            <div className="main__left" />
            <div className="main__right">
                <div className="components">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-8 col-lg-6">
                                {content()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}