import React from "react";
import { AppArticlesActions } from "../../app/Articles/Actions";
import { PageTopbar } from "../../components/Page/Topbar";
import { PageHeader } from "../../components/Page/Header";

export function RoutesArticlesActions({ title, parents, current }) {

    return (
        <div>

            <PageTopbar
                parents={parents}
                current={current}
            />

            <PageHeader
                title={title}
                tabGroup="product-overview"
                parents={parents}
                current={current}
            />

            <AppArticlesActions
                parents={parents}
                current={current}
            />
        </div>
    );
}