import React, { useState, useEffect } from "react";
import { actionProductProcessReservation, getProductDetails } from "../../../helpers/articles";
import { ValidateResponse } from "../../../helpers/global";
import { useNavigate } from "react-router-dom";
import { FormFields } from "../../../../components/Form/Fields";
import { FormInputSubmit } from "../../../../components/Form/Input/Submit";
import { Message } from "../../../../components/Message";
import { ContentWrapper } from "../../../../components/Content/Wrapper";

export function AppArticlesProductActionsReservation({ productId, parents }) {

    const navigate = useNavigate();
    const [error, setError] = useState();
    const [formErrors, setFormErrors] = useState({});
    const [values, setValues] = useState({});
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {

        getProductDetails(productId).then((res) => {
            const response = ValidateResponse(res.data, 'details');
            if (response.status === 'success') {
                let responseValues = {
                    productId: productId,
                    enableReservation: response.details.enableReservation,
                    reservationReason: response.details.reservationReason,
                }
                setValues(responseValues);
                setIsLoaded(true);
            }
            else {
                setError(response.message);
            }
        })
    }, [productId])

    const handleInput = (name, value) => {

        let newValues = values;

        newValues[name] = value;

        setValues(newValues);
        return;
    }

    const sendValues = () => {

        let postData = values;
        let requiredValues = [];

        postData['productId'] = productId;
        postData['enableReservation'] = values.enableReservation;
        postData['reservationReason'] = values.reservationReason;

        if (values.enableReservation) {
            requiredValues = ['reservationReason'];
        }

        // Validate required fields
        let validation = {};

        requiredValues.forEach((requiredField) => {
            if (postData[requiredField] === undefined) {
                // setError('Veld niet gevonden: ' + requiredField);
                validation[requiredField] = 'Verplicht veld';
            }
            else if (postData[requiredField] === null || postData[requiredField].length === 0) {
                validation[requiredField] = 'Verplicht veld';
            }
        });

        if (Object.keys(validation).length !== 0) {
            setFormErrors(validation);
            return;
        }

        actionProductProcessReservation(postData).then((res) => {

            const response = ValidateResponse(res.data, 'status');
            if (response.status === 'success') {
                navigate("/" + parents.replace("actions", "details/") + productId, {
                    replace: true,
                    state: { message: response.message, type: response.status }
                });
            }
            else {
                setError(response.message);
            }
        }).catch((error) => {
            const response = ValidateResponse(error);
            setError(response.message);
        })
    }

    if (isLoaded === false) {
        return (<Message type="loading" />);
    }
    let fields = {
        'enableReservation': { type: 'checkbox', label: 'Reservering actief' },
        'reservationReason': { type: 'textarea', label: "Uitleg/ reden reservering" }
    }

    let title = "Reparatie registreren";

    const content = () => {
        return <>
            <h3 className="box-header">{title}</h3>
            <FormFields
                formFields={fields}
                setValues={handleInput}
                setErrors={setFormErrors}
                formValues={values}
                formErrors={formErrors}
                identifier="product-add"
            />
            <FormInputSubmit
                handleSubmit={sendValues}
                cancelUri={"/" + parents + "/" + productId}
                submitLabel={"Verzenden"}
            />
        </>
    }

    return (
        <>
            <Message type="error" message={error} />
            <ContentWrapper
                type="outline-box"
                content={content()}
                className="outline-box--form"
            />
        </>
    );
}