import React, { useState } from "react";
import { useParams } from "react-router-dom";
//import { Identity } from "../../../components/Identity";
import { PageTopbar } from "../../../components/Page/Topbar";
import { PageHeader } from "../../../components/Page/Header";
import { AppArticlesDepotDetails } from "../../../app/Articles/Depot/Details";

export function RoutesArticlesDepotDetails({ parents, current }) {

    let identifier = (parents + current).replaceAll('/', '-');

    const { depotCaseId } = useParams();
    const [title, setTitle] = useState(null);

    return (
        <div>

            <PageTopbar
                parents={parents}
                current={current}
            />

            {/* <Identity /> */}

            <PageHeader
                title={title}
                parents={parents}
                current={current}
                variables={{ depotCaseId: depotCaseId }}
                identifier={identifier}
            />

            <AppArticlesDepotDetails
                depotCaseId={depotCaseId}
                identifier={identifier}
                setTitle={setTitle}
            />
        </div>
    );
}