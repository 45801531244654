import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { processRentalRequest } from "../helpers/rentals";
import { ValidateResponse } from "../helpers/global";
import { Message } from "../../components/Message";
import { ContentWrapper } from "../../components/Content/Wrapper";
import { FormInputSubmit } from "../../components/Form/Input/Submit";
import { FormInputCategories } from "../../components/Form/Input/Categories";
import { FormFields } from "../../components/Form/Fields";

export function AppRentalsRequest({ parents }) {

    const auth = JSON.parse(localStorage.getItem('auth'));

    const navigate = useNavigate();
    const [error, setError] = useState();
    const [formErrors, setFormErrors] = useState({});
    const [values, setValues] = useState({contactId: auth.userId});
    const [articleList, setArticleList] = useState([]);
    const [rentalCategory, setRentalCategory] = useState({});
    const [refresh, setRefresh] = useState(false);
    
    const redirectPath = "/" + parents;

    const formFields = {
        deliverDate: { type: 'date', label: 'Gewenste afleverdatum', options: { min: null, max: "+1" }, rules:["required"]},
        urgency: { type: 'checkbox', label: 'Spoed' },
        location: { type: 'location', rules:["required"]},
        generalUse: { type: 'checkbox', label: 'Algemeen gebruik' },
        clientName: { type: 'text', label:"Naam cliënt"},
        birthDate: { type: 'date', label:"Geboortedatum", options: { min: "-125", max: null }  },
        customerReference: { type: 'text', label: 'Uw referentie' },
        contactId: {type: "contacts", label: "Contactpersoon", rules:["required"]},
        comments: { type: 'textarea', label: 'Opmerkingen' }
    }

    const handleInput = (name, value) => {

        let newValues = values;
        newValues[name] = value;

        setValues(newValues);
        setRefresh(refresh === false);
    }

    const setRequestValues = (selectedKey, selectedValues) => {

        if (selectedValues.selected === undefined && selectedKey === 'articleCategory') {
            setRentalCategory(selectedValues);
            return;
        }
        else if(selectedValues.categoryParents === undefined) {
            return;
        }

        let articleCategoryId = selectedValues.selected;
        let articleName = null;
        let articleKey = 'C' + articleCategoryId;
        let priceDisplay;

        selectedValues.categoryParents.forEach((parent) => {
            articleName === null
                ? articleName = parent.title
                : articleName += ' | ' + parent.title;

            if (parent.id === articleCategoryId) {
                priceDisplay = parent.priceDisplay;
            }

        });
   
        setArticleList((articleList) => [
            ...articleList,
            {
                categoryId: articleCategoryId,
                name: articleName,
                amount: 1,
                key: articleKey,
                priceDisplay: priceDisplay
            }
        ]);

        setError(null);
        setRentalCategory({});
    }

    const handleArticleRemove = (remove) => {

        let list = [];

        articleList.forEach(
            function (article, index) {
                if (index !== remove) {
                    list.push(article);
                }
            }
        );
        setArticleList(list);
        setError(null);
    }

    const handleArticleAmount = (event) => {

        let newAmount = event.target.value;
        let changeCategoryId = event.target.dataset.key;

        let list = [];

        articleList.forEach(
            function (article) {
                if (article.categoryId === changeCategoryId) {
                    article.amount = newAmount;
                }
                list.push(article);
            }
        );

        setArticleList(list);
    };

    const handleFormErrors = (name, value) => {
        let newErrors = formErrors;
        newErrors[name] = value;
        setFormErrors(newErrors);
    }

    const handleRequest = () => {

        let errors = [];

        // 1. check op verplichte velden
        if (articleList.length === 0 ) {
            errors.push("Selecteer een artikel");
        }   
        if (values.locationId === undefined || values.locationId === null || !values.locationId > 0 ) {
            errors.push("Selecteer een locatie");
        }  
        if (values.deliverDate === undefined || values.deliverDate === null) {
            errors.push("Selecteer een gewenste leverdatum");
        } 

        if (values.contactId === undefined || values.contactId === null) {
            errors.push("Selecteer een contactpersoon");
        }

        if (errors.length > 0) {
            let formError = errors.join(", ");
            setError(formError);
            return;
        }
        // 2. Setup formdata
        let formData = values;
        formData.articles = articleList;

        if (formData.generalUse !== undefined) {
            formData.generalUse = "Ja";
        }

        processRentalRequest(formData).then((res) => {

            const response = ValidateResponse(res.data, 'status');
            if (response.status === 'success') {
                navigate(redirectPath, {
                    replace: true,
                    state: { message: response.message, type:response.status }
                });
            }
            else {
                setError(response.message);
            }
        }).catch((error) => {
            const response = ValidateResponse(error);
            setError(response.message);
        })
    }

    const content = () => {

        let submitLabel = "Aanvragen";

        return (
            <>
                <label htmlFor="" className="form__label">Hulpmiddel * </label>
                {/** KIES VERHUUR CATEGORIE */}
                {articleList.map((article, index) => (

                    <div key={"article" + index + article.key}>

                        <div style={{ float: 'left', minWidth: '70%', minHeight: '35px' }}>
                            <div style={{ display: 'table-cell', verticalAlign: 'middle', padding: '10px 10px 10px 0' }}>
                                {article.name} {article.priceDisplay}
                            </div>
                        </div>
                        <div style={{ float: 'left', minWidth: '30%', minHeight: '35px', textAlign: 'right' }}>
                            <div style={{ display: 'table-cell', padding: '10px 10px 10px 0' }}>
                                Aantal:
                            </div>
                            <div style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                                <input type="text" style={{ padding: '0px', textAlign: 'center', width: '35px', height: '35px' }}
                                    onKeyUp={handleArticleAmount}
                                    data-key={article.categoryId}
                                    name={article.key}
                                    maxLength='2'
                                    defaultValue={article.amount ?? 1}
                                />
                            </div>
                            <div style={{ display: 'table-cell' }}>
                                <div
                                    style={{ marginLeft: '10px' }}
                                    className="details-link details-link--icon details-link--icon--remove"
                                    onClick={() => { handleArticleRemove(index) }}>&nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                <FormInputCategories
                    fieldName="articleCategory"
                    fieldValue={rentalCategory}
                    output="catalog"
                    fields={{category:"categoryId", selectLabel:"Toevoegen op aanvraag"}}
                    setValues={setRequestValues}
                    outputType="rentacatalog"
                />

                <FormFields
                    formFields={formFields}
                    setValues={handleInput}
                    setErrors={handleFormErrors}
                    formValues={values}
                    formErrors={formErrors}
                />

                <FormInputSubmit
                    handleSubmit={handleRequest}
                    cancelUri={redirectPath}
                    submitLabel={submitLabel}
                />
            </>
        );
    }

    return (
        <>
            <Message type="error" message={error} />

            <ContentWrapper
                type="outline-box"
                content={content()}
                className="outline-box--form"
            />
        </>
    );
}