import React, { useEffect, useState } from "react";
import { ValidateResponse, fetchWarehouses} from "../../../app/helpers/global";
import { Message } from "../../Message";
import { FormRender } from "../Render";

export function FormInputWarehouse({
    setValues, // required
    fieldValue = {},  // data object {locationId:.., departmentId:.., room:..} optional
    fieldLabel = { warehouseId: "Magazijn"},  // optional
    identifier, // optional
    fields = { warehouseId: "warehouseId"} // choose the fields to show on location & pass the names. 
}) {

    const [error, setError] = useState();
    const [warehouses, setWarehouses] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {

        fetchWarehouses(1).then((res) => {

            const response = ValidateResponse(res.data, 'warehouses');

            if (response.status === 'success') {
                setWarehouses(response.warehouses);
                setIsLoaded(true);
            }
            else {
                setError(response.message);
            }
        }).catch((error) => {
            const response = ValidateResponse(error, 'status');
            setError(response.message);
        });

    }, []);

    const handleValues = (name, value) => {
        setValues(name, value, identifier);
    }

    if (error !== undefined) {
        return (<Message type="error" message={error} />);
    }
    if (isLoaded === false) {
        return (<Message type="loading" />);
    }
    if (warehouses === undefined || warehouses === null || Object.keys(warehouses).length === 0) {
        return (<Message type="noData" />);
    }

    return (
        <>
            {warehouses.length > 0 &&
                <FormRender
                    setValues={handleValues}
                    fieldType="select"
                    fieldName={fields.warehouseId}
                    fieldValue={fieldValue.warehouseId}
                    fieldLabel={fieldLabel.warehouseId}
                    identifier={identifier}
                    fieldOptions={warehouses}
                />
            }
        </>
    )
}