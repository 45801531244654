import React from "react";
import { AppScan } from "../../../app/Scan";
//import { Identity } from "../../../components/Identity";
import { PageTopbar } from "../../../components/Page/Topbar";

export function RoutesArticlesActionsScan({ title, parents, current }) {

    return (
        <>
            <PageTopbar
                parents={parents}
                current={current}
            />

            {/* <Identity /> */}

            <AppScan
                method="search"
                returnPath={"/" + parents}
                title={title}
            />
        </>
    );
}