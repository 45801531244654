/**
 * @see helpers/global
 */
import axios from 'axios';
import { API_URL, getApiHeaders } from "./api.js"

// POST 
const fetchCustomerRequests = postData => {
    return axios.post(`${API_URL}/customer/requests/overview`, postData, getApiHeaders())
}
// GET
const fetchRequestDetails = customerRequestId => {
    return axios.get(`${API_URL}/customer/requests/details/` + customerRequestId, getApiHeaders())
}

export {
    fetchCustomerRequests,
    fetchRequestDetails
}
