import React, { useEffect, useState } from "react";
import { ValidateResponse, getCurrentPage, setCurrentPage } from "../helpers/global";
import { fetchContacts } from "../helpers/customer";
import { fetchRentalCases } from "../helpers/rentals";
import { List } from "../../components/List";
import {  ListPagination } from "../../components/List/Pagination";
import { Message } from "../../components/Message";

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function AppRentalsOverview({ current, setFilterFields, filterValues = {}, identifier }) {

    let pageSize = 10; // default values ergens globaal opslaan?

    const [cases, setCases] = useState();
    //const [contacts, setContacts] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [loadFilters, setLoadFilters] = useState(true);
    const [pageNr, setPageNr] = useState(getCurrentPage(current));
    const [totalCount, setTotalCount] = useState(0);
    const [error, setError] = useState();
    const [filters, setFilters] = useState(JSON.parse(localStorage.getItem("filters" + identifier)) ?? {});
    const [filterString, setFilterString] = useState("{}");

    const defineFilterFields = (contacts) => {

        let filterFields = {
            location: { type: 'location', label:{locationId:"Verhuurlocatie", departmentId:"Afdeling", room: "Kamernr."}},
            // invoiceEntity: { type: 'location', options:{locationId:"invoiceEntityId"}, label:{locationId:"Financieel verantwoordelijke"}},
            articleCategory: { type: 'categories', label: "Artikelcategorie", options: { output: "rental", category:"categoryId", article:"articleId"}}, 
            contactId: {type: 'select', label:"Contactpersoon", options:contacts},
            serialNumber: { type: 'text', label: "Serienr." },
            frameNumber: { type: 'text', label: "Framenr." },
            rentalCaseId: {type:'text', label:"Dossiernr."},
            endUser: {type:'text', label:"Eindgebruiker"},
            birthDate: {type:'date', label:"Geboortedatum", options:{min:"125", max:null}},
        };

        setFilterFields(filterFields);

        return filterFields;
    }

    if (JSON.stringify(filterValues) !== filterString) {
        setFilterString(JSON.stringify(filterValues));
        setIsLoaded(false);
        setFilters(filterValues);
        setCurrentPage(1, current, setPageNr);
    }

    if (pageNr !== getCurrentPage(current)) {
        setPageNr(getCurrentPage(current));
    }

    const handlePagination = (page) => {
        setCurrentPage(page, current, setPageNr);
    }

    let listColumns = [
        { key: "displayName", type: "link", path: "details/:rentalCaseId", hideView: "large" }, //0
        { key: "rentalCaseId", label: "Dossiernr.", type: "text" },//1
        { key: "displayName", label: "Artikelnaam", type: "text", hideView: 'small' },//2
        { key: "startDate", label: "Startdatum", type: "date" },//3
        { key: "endDate", label: "Einddatum", type: "date" },//4
        { key: "departmentRoom", label: "Afdeling/ kamernr.", type: "text" },
        { key: "endUser", label: "Eindgebruiker", type: "text" },
        { key: "companyName", label: "locatie", type: "text" },
        { key: "details", type: "link", path: "details/:rentalCaseId", hideView: "small" },
    ];

    if (identifier.indexOf('active') !== -1) {
        delete listColumns[4];
    }
    if (identifier.indexOf('waiting') !== -1) {
        delete listColumns[4];
        delete listColumns[5];
    }

    useEffect(() => {

        async function fetchData() {

            const callData = {
                tabType: current,
                filters: {
                    limitOffset: pageSize * pageNr - (pageSize - 1),
                    limitRows: pageSize,
                    ...filters
                }
            }

            await fetchContacts(callData).then((res) => {
                const response = ValidateResponse(res.data, 'contacts');
                if (response.status === 'success') {
                   // setContacts(response.contacts);
                                            
                    if (loadFilters !== current) {
                        defineFilterFields(response.contacts);
                        setLoadFilters(current);
                    }
                }
            });

            await fetchRentalCases(callData).then((res) => {

                const response = ValidateResponse(res.data, 'cases');

                if (response.status === 'success') {

                    if (response.totalCount !== undefined) {
                        setTotalCount(response.totalCount);

                    }
                    response.cases.forEach((item) => {
                        if(item.generalUse === 1){
                            item.endUser = 'Algemeen gebruik';
                        }
                    });
                    
                    setCases(response.cases);
                    setIsLoaded(true);
                }
                else {
                    setError(response.message);
                }

            }).catch((error) => {
                const response = ValidateResponse(error);
                setError(response.message);
            });
        }
        fetchData();
    }, [pageNr, filterString, current, filters, pageSize]);

    if (error !== undefined) {
        return (<Message type="error" message={error} />);
    }
    if (isLoaded === false) {
        return (<Message type="loading" />);
    }
    if (cases === undefined || cases === null || Object.keys(cases).length === 0) {
        return (<Message type="noData" />);
    }

    return (
        <>
            <List
                columns={listColumns}
                data={cases}
                identifier={identifier}
            />

            <div className={'spacer'}></div>

            <ListPagination
                currentPage={pageNr}
                setCurrentPage={handlePagination}
                totalCount={totalCount}
                pageSize={pageSize}
            />
            <div className={'spacer'}></div>
        </>
    );
}