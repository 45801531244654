import React from "react";
import { PageTitle } from "./Title";
import { PageTabs } from "./Tabs";
import { Message } from "../Message";
import { useLocation } from "react-router-dom";


// message, messageType,
export function PageHeader({ 
    title, 
    parents, 
    current, 
    tabGroup, 
    variables, 
    filterFields, 
    setFilterValues, 
    message, 
    messageType,
    identifier 
}) {

    if (parents !== undefined && current !== undefined) {
        identifier = identifier ?? (parents+current).replaceAll('/', '-');
    }
    else {
        identifier = "pageheader";
    }

    const location = useLocation();
    if (message === undefined || message === null) {
        message = location.state !== null ? location.state.message : null;
    }
    if (messageType === undefined || messageType === null) {
        messageType = location.state !== null ? location.state.type : null;
    }

    return (
        <div className="page-header">
            <PageTitle
                title={title}
                filterFields={filterFields}
                setFilterValues={setFilterValues}
                identifier={identifier}
            />
            <PageTabs
                parents={parents}
                tabGroup={tabGroup}
                variables={variables}
            />
            <Message
                message={message}
                type={messageType}
            />
        </div>
    );
}