import React, { useState, useEffect } from "react";
import { ValidateResponse } from "../../../helpers/global";
import { checkRequest } from "../../../helpers/customer";
import { getProductDetails, actionProductDepotIn } from "../../../helpers/articles";
import { useNavigate } from "react-router-dom";
import { FormFields } from "../../../../components/Form/Fields";
import { FormInputSubmit } from "../../../../components/Form/Input/Submit";
import { Message } from "../../../../components/Message";
import { ContentWrapper } from "../../../../components/Content/Wrapper";

export function AppArticlesProductActionsDepot({ productId, parents }) {

    const auth = JSON.parse(localStorage.getItem('auth'));

    const navigate = useNavigate();
    const [state, setState] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [error, setError] = useState();
    const [formErrors, setFormErrors] = useState({});
    const [values, setValues] = useState({contactId: auth.userId});

    useEffect(() => {

        checkRequest(productId + '/REPAIR-DEPOT').then((res) => {
            const response = ValidateResponse(res.data, 'status');
            if (response.status !== 'success') {
                setError(response.message);
            }
            setIsChecked(true);
        })
    }, [productId, navigate]);

    useEffect(() => {

        getProductDetails(productId).then((res) => {
            const response = ValidateResponse(res.data, 'details');
            if (response.status === 'success') {

                setState(response.details);

                let responseValues = {
                    ...values,
                    productId: productId
                }
                setValues(responseValues);
                setIsLoaded(true);
            }
            else {
                setError(response.message);
            }
        })
    }, [productId])

    const handleInput = (name, value, identifier) => {

        let newValues = values;

        if (identifier !== undefined && identifier !== null) {
            if (newValues[identifier] === undefined) {
                newValues[identifier] = {}
            }
            newValues[identifier][name] = value;
        }
        else {
            newValues[name] = value;
        }

        setValues(newValues);
        return;
    }

    const sendValues = () => {

        let requiredValues = ['comments', 'contactId'];
        let validation = {};

        requiredValues.forEach((requiredField) => {
            if (values[requiredField] === undefined || values[requiredField] === null || values[requiredField].length === 0) {
                validation[requiredField] = 'Verplicht veld';
            }
        });

        if (Object.keys(validation).length !== 0) {
            setFormErrors(validation);
            return;
        }

        let postData = {
            'productId': values.productId,
            'comments': values.comments,
            'deliverDate': values.deliverDate,
            'contactId': values.contactId
        }

        actionProductDepotIn(postData).then((res) => {

            const response = ValidateResponse(res.data, 'status');

            if (response.status === 'success') {
                navigate("/" + parents.replace("actions", "details/") + productId, {
                    replace: true,
                    state: { message: response.message, type: response.status}
                });
            }
            else {
                setError(response.message);
            }
        }).catch((error) => {
            const response = ValidateResponse(error);
            setError(response.message);
        })
    }

    if (error !== undefined) {
        return (<Message type="error" message={error} />);
    }
    if (isLoaded === false || isChecked === false) {
        return (<Message type="loading" />);
    }
    if (state === undefined || state === null || Object.keys(state).length === 0) {
        return (<Message type="noData" />);
    }

    const fields = {
        'deliverDate': { type: "date", label: "Gewenste ophaaldatum", options:{min:null, max:"+1"}},
        'contactId': {type: "contacts", label: "Contactpersoon", rules:["required"]},
        'comments': { type: 'textarea', label: "Opmerkingen" }
    }

    const content = () => {
        return (
            <>
                <h3 className="box-header">Aanvraag depotopslag</h3>

                <FormFields
                    formFields={fields}
                    setValues={handleInput}
                    setErrors={setFormErrors}
                    formValues={values}
                    formErrors={formErrors}
                />
                <FormInputSubmit
                    handleSubmit={sendValues}
                    cancelUri={"/" + parents + "/" + productId}
                />
            </>
        )
    }
    return (
        <ContentWrapper
            type="outline-box"
            content={content()}
            className="outline-box--form"
        />
    );
}