import React, { useEffect, useState } from "react";
import { ValidateResponse, getCurrentPage, setCurrentPage } from "../../helpers/global";
import { fetchRentalDocuments } from "../../helpers/rentals";
import { List } from "../../../components/List";
import {  ListPagination } from "../../../components/List/Pagination";
import { Message } from "../../../components/Message";

export function AppRentalsCasesDocuments ({ rentalCaseId, current, identifier }) {

    let pageSize = 10; // default values ergens globaal opslaan?

    const [documents, setDocuments] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [pageNr, setPageNr] = useState(getCurrentPage(current));
    const [totalCount, setTotalCount] = useState(0);
    const [error, setError] = useState();

    if (pageNr !== getCurrentPage(current)) {
        setPageNr(getCurrentPage(current));
    }

    const handlePagination = (page) => {
        setCurrentPage(page, current, setPageNr);
    }

    let listColumns = [
        { key: "documentNumber", type: "title", hideView: "large"}, //0
        { key: "documentNumber", type: "text", label: "Documentnr.", hideView: "small"},//1
        { key: "documentName", type: "text", label: "Document"},//2
        { key: "createDate", type: "date", label: "Aanmaakdatum"},//2
        { 
            key: "documentNumber", 
            label: "Download document", 
            type: "download", 
            display:"icon-download", 
            documentType:"documentType", 
            mimeType: "mimeType",
            fileName: "fileName"
        }
    ];

    useEffect(() => {

        async function fetchData() {

            const callData = {
                tabType: current,
                rentalCaseId: rentalCaseId,
                filters: {
                    limitOffset: pageSize * pageNr - (pageSize - 1),
                    limitRows: pageSize
                }
            }

            await fetchRentalDocuments(callData).then((res) => {

                const response = ValidateResponse(res.data, 'documents');

                if (response.status === 'success') {

                    if (response.totalCount !== undefined) {
                        setTotalCount(response.totalCount);
                    }
                    setDocuments(response.documents);
                    setIsLoaded(true);
                }
                else {
                    setError(response.message);
                }

            }).catch((error) => {
                const response = ValidateResponse(error);
                setError(response.message);
            });
        }
        fetchData();
    }, [pageNr, current, pageSize, rentalCaseId]);

    if (error !== undefined) {
        return (<Message type="error" message={error} />);
    }
    if (isLoaded === false) {
        return (<Message type="loading" />);
    }
    if (documents === undefined || documents === null || Object.keys(documents).length === 0) {
        return (<Message type="noData" />);
    }

    return (
        <>
            <List
                columns={listColumns}
                data={documents}
                identifier={identifier}
            />

            <div className={'spacer'}></div>

            <ListPagination
                currentPage={pageNr}
                setCurrentPage={handlePagination}
                totalCount={totalCount}
                pageSize={pageSize}
            />
            <div className={'spacer'}></div>
        </>
    );
}