/**
 * @documentation helpers
 * 
 * * Every segment gets it's own helper
 * * Keep api-axios requests on op of the file, functions at the botton
 * * Export in bulk at the botton of the file, not individually
 * * In global are all the GLOBAL functions and fetches that are the same for CLIENT & INTERN
 * * ->need specific global function specific for client or intern? make a client or intern helper or see if you can place is under a segment
 */
import axios from 'axios';
import { API_URL, getApiHeaders, getApiHeadersPdf } from "./api.js"

// GLOBAL API GET calls 
const fetchDownload = (documentType, documentId) => axios.get(
    `${API_URL}/global/download/` + documentType + `/` + documentId, getApiHeadersPdf()
);

const fetchPageTitle = (type, id) => axios.get(
    `${API_URL}/global/pagetitle/` + type + `/` + id, getApiHeaders()
);

const fetchLocations = entityId => axios.get(
    `${API_URL}/global/locations/` + entityId, getApiHeaders()
)

const fetchWarehouses = () => axios.get(
    `${API_URL}/global/warehouses`, getApiHeaders()
);

const fetchDepartments = locationId => axios.get(
    `${API_URL}/global/departments/` + locationId, getApiHeaders()
)

const fetchProductImages = productId => axios.get(
    `${API_URL}/global/product/images/` + productId, getApiHeaders()
);
const fetchProductDocuments = productId => axios.get(
    `${API_URL}/global/product/documents/` + productId, getApiHeaders()
);

// GLOBAL API POST calls
const fetchArticleCategories = postData => axios.post(
    `${API_URL}/global/articles/categories`, postData, getApiHeaders()
);
const fetchCategoryArticles = postData => axios.post(
    `${API_URL}/global/articles/list`, postData, getApiHeaders()
);

const fetchInspectionTypes = () => axios.get(
    `${API_URL}/global/inspections/types`, getApiHeaders()
);

/**
 * *** FUNCTIES ***
 * FORBIDDEN & SESSION_EXPIRED worden in Message afgehandeld. Resp. redirects naar /home of /logout
 */

const ValidateResponse = (data, identifier = 'response') => {

    let errorMessage = "Er is een fout opgetreden met het ophalen/verwerken van de gegevens, excuses voor het ongemak";

    if (data === null || data === undefined || typeof(data) !== 'object') {
        data = {
            status: 'error',
            message: errorMessage
        }
    }
    else if (data.status === "SESSION_EXPIRED") {
        data.status = 'error';
        data.message = "SESSION_EXPIRED";
    }
    else if (data.response !== undefined && data.response !== null && data.response.status === 403) {
        data.status = 'error';
        data.message = "FORBIDDEN";
    }
    else if (data.status === 500
        || data.status === 404
        || (data.status === 'error' && data.message === undefined)
        || (data.status !== 'error' && data[identifier] === undefined)
    ) {

        if (data.status === 'error' && data[identifier] !== undefined && data.message !== undefined) {
            errorMessage = data.message;
        }

        if (window.location.host === "localhost:3000") {
            if (data.status === 500 || data.status === 404) {
                errorMessage += " (http " + data.status + ")";
            }
            else if (data[identifier] === undefined) {
                errorMessage += ' (identifier mist in response: ' + identifier + ' )';
            }
        }

        data = {
            status: 'error',
            message: errorMessage
        }
    }

    if (data.status !== 'error' && data.status !== 'success' && window.location.host === "localhost:3000") {
        data.status = 'error';
        data.message = "De data response status wordt wordt niet in het juiste formaat verstuurd: " + data.status;
    }

    return data;
}

const resolvePath = (path, variables = {}) => {

    if (path !== undefined && path.indexOf(':') !== -1) {

        let params = {};

        path.split('/').forEach((segment) => {

            if (segment.indexOf(':') === 0) {
                let paramKey = segment.replace(":", "");
                params[paramKey] = variables[paramKey] ?? variables[segment];
            }
        });

        Object.keys(params).forEach((key) => {

            let paramKey = key.indexOf(":") === 0 ? key.replace(":", "", key) : key;

            if (path.indexOf(paramKey) !== -1) {
                let value = params[key];
                path = path.replace(":" + paramKey, value);
            }
        });
    }

    return path;
}

const formatDate = (input) => {
    if (input === undefined || input === null) {
        return;
    }

    if (input.indexOf('-') === 4) {
        let parts = input.substring(0, 10).split('-');

        return parts[2] + "-" + parts[1] + "-" + parts[0];
    }
    return input;
}

const getCurrentPage = (identifier) => {
    let lastpage = JSON.parse(localStorage.getItem("last-page"));
    if (lastpage !== null && lastpage !== undefined && lastpage.identifier === identifier) {
        return lastpage.pageNr;
    }
    return 1;
}

const setCurrentPage = (pageNr, identifier, setPage) => {
    localStorage.setItem("last-page", JSON.stringify({ identifier: identifier, pageNr: pageNr }));

    if (setPage !== undefined) {
        setPage(pageNr);
    }
}

const getPageTitle = (type, id, setTitle) => {

    let titleObj = JSON.parse(localStorage.getItem(type + id));

    if (titleObj !== undefined && titleObj !== null && typeof (titleObj) === 'object') {
        if (titleObj.name !== undefined) {
            setTitle(titleObj.name);
        }
    }
    else {
        fetchPageTitle(type, id).then((res) => {
            const response = res.data;
            if (response.status === 'success') {
                if (titleObj === undefined || titleObj === null) {
                    titleObj = {};
                }
                titleObj.name = response.response
                localStorage.setItem(type + id, JSON.stringify(titleObj))
                setTitle(titleObj.name);
            }
        });
    }
}

export {
    fetchLocations,
    fetchDepartments,
    fetchDownload,
    fetchPageTitle,
    fetchWarehouses,
    fetchArticleCategories,
    fetchCategoryArticles,
    fetchProductImages,
    fetchProductDocuments,
    fetchInspectionTypes,

    // components
    ValidateResponse,

    // functions
    resolvePath,
    formatDate,
    getCurrentPage,
    setCurrentPage,
    getPageTitle,
}