import React from "react";
import { PageTopbar } from "../../components/Page/Topbar";
import { AppAuthLogin } from "../../app/Auth/Login";

export function RoutesAuthLogin({ parents, current }) {
    
    localStorage.clear();

    return (
        <>
            <PageTopbar
                parents={parents}
                current={current}
            />
   
            <AppAuthLogin />
        </>
    );
}