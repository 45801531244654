import React from "react";
import { PageTopbar } from "../../components/Page/Topbar";
import { AppAuthForgot } from "../../app/Auth/Forgot";

export function RoutesAuthForgot({ parents, current }) {

    return (
        <>
            <PageTopbar
                parents={parents}
                current={current}
            />
   
            <AppAuthForgot />
        </>
    );
}