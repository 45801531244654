import React, { useEffect, useState } from "react";
import { ValidateResponse, getCurrentPage, setCurrentPage } from "../../helpers/global";
import { fetchProductInspections } from "../../helpers/articles";
import { List } from "../../../components/List";
import { ListPagination } from "../../../components/List/Pagination";
import { Message } from "../../../components/Message";

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function AppArticlesInspectionsOverview({ current, setFilterFields, filterValues, identifier }) {

    let pageSize = 10; // default values ergens globaal opslaan?

    const [inspections, setInspections] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [loadFilters, setLoadFilters] = useState();
    const [pageNr, setPageNr] = useState(getCurrentPage(current));
    const [totalCount, setTotalCount] = useState(0);
    const [error, setError] = useState();
    const [filters, setFilters] = useState(JSON.parse(localStorage.getItem("filters" + identifier)));
    const [filterString, setFilterString] = useState("{}");

    if (JSON.stringify(filterValues) !== filterString) {
        setFilterString(JSON.stringify(filterValues));
        setIsLoaded(false);
        setFilters(filterValues);
        setCurrentPage(1, current, setPageNr);
    }

    if (pageNr !== getCurrentPage(current)) {
        setPageNr(getCurrentPage(current));
    }

    const handlePagination = (page) => {
        setCurrentPage(page, current, setPageNr);
    }

    let listColumns = [
        { key: "statusLabel", label: "Status", type: "text" }, //0
        { key: "plannedDate", label: "Te keuren voor", type: "date" }, //1
        { key: "inspectDate", label: "Keuringsdatum", type: "date" }, //2
        { key: "serialNumber", label: "Serienummer", type: "text" }, //3
        { key: "name", label: "Artikelnaam", type: "text" }, //4
        { key: "owner", label: "Locatie", type: "text" }, //5
        { key: "inspectionNumber", label: "Keuringsrapport", type: "download", display: "icon", fileName: "Keuringsrapport-vdMark", documentType: "inspection" }
    ];


    // planned date or inspect date. let op! op key, dus checke als er kolom bij of af gaat!
    if (current === 'open') {
        delete (listColumns[2]);
        delete (listColumns[6]);
    }
    else if (current === 'archive') {
        delete (listColumns[1]);
    }
    else if (current === 'inspections') {
        delete (listColumns[5]);
    }

    let filterFields = {}

    if (current === 'inspections') {
        const optionsConclusion = [
            { value: "PENDING", label: "Te keuren" }, // new/processing/waiting
            { value: "COMPLETED", label: "Goedgekeurd" }, // new/processing/waiting
            { value: "REJECTED", label: "Afgekeurd" }, // new/processing/waiting
        ]
        filterFields = {
            'conclusion': { type: 'select', label: "Status", options: optionsConclusion },
        }
    }
    else {
        filterFields = {
            'articleCategory': { type: 'categories', label: "Artikelcategorie", options: { output: "depot", category: "categoryId", article: "articleId" } },
            'location': { type: 'location', options: { locationId: "locationId" }, label: { locationId: "Locatie" } },
            'serialNumber': { type: 'text', label: "Serienummer" },
            'dateFrom': { type: 'date', label: "Vanaf keuringsdatum", options: { min: "2015", max: null } },
            'dateTo': { type: 'date', label: "Tot keuringsdatum", options: { min: "2015", max: null } },
        }
    }

if (isLoaded === true && loadFilters !== current) {
        setFilterFields(filterFields);
        setLoadFilters(current);
    }

    useEffect(() => {

        async function fetchData() {

            const callData = {
                context: "overview-" + current,
                filters: {
                    limitOffset: pageSize * pageNr - (pageSize - 1),
                    limitRows: pageSize,
                    ...filters
                }
            }

            await fetchProductInspections(callData).then((res) => {

                const response = ValidateResponse(res.data, 'inspections');
                if (response.status === 'success') {

                    if (response.totalCount !== undefined) {
                        setTotalCount(response.totalCount);
                    }
                    setInspections(response.inspections);
                    setIsLoaded(true);

                }
                else {
                    setError(response.message);
                }
            }).catch((error) => {
                const response = ValidateResponse(error);
                setError(response.message);
            });
        }

        fetchData();
    }, [current, pageNr, filterString, filters, pageSize]);

    if (error !== undefined) {
        return (<Message type="error" message={error} />);
    }
    if (isLoaded === false) {
        return (<Message type="loading" />);
    }
    if (inspections === undefined || inspections === null || Object.keys(inspections).length === 0) {
        return (<Message type="noData" />);
    }



    return (
        <>
            <List
                columns={listColumns}
                data={inspections}
                identifier={identifier}
            />

            <div className={'spacer'}></div>

            <ListPagination
                currentPage={pageNr}
                setCurrentPage={handlePagination}
                totalCount={totalCount}
                pageSize={pageSize}
            />
            <div style={{ height: '30px' }}></div>
        </>
    );
}