// import React, { useState} from "react";
// import { useLocation, useParams } from "react-router-dom";
//import { Identity } from "../../../components/Identity";
import { PageTopbar } from "../../../components/Page/Topbar";
import { PageHeader } from "../../../components/Page/Header";
import { AppRentalsRequest } from "../../../app/Rentals/Request";

export function RoutesRentalsActionsRequest({ title, parents, current }) {

    return (
        <div>
            <PageTopbar
                parents={parents}
                current={current}
            />

            {/* <Identity /> */}

            <PageHeader
                title={title}
                parents={parents}
                current={current}
            />

            <AppRentalsRequest
                parents={parents}
                // redirectUri={ "/" + parents + "/details/" + rentalCaseId }
                // cancelUri={ "/" + parents + "/details/" + rentalCaseId }
                //requestType="RENTAL-REQUEST"
            />
        </div>
    );
}