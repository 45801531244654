import { useState, useEffect } from "react";
import { Navigate, useLocation, Outlet } from "react-router-dom";
import { checkIdentity } from "../../app/helpers/auth";
import { ValidateResponse } from "../../app/helpers/global";

export const AuthWrapper = () => {

    const location = useLocation();
    const auth = JSON.parse(localStorage.getItem('auth'));
    const [ authStatus, setAuthStatus ] = useState(); 

    useEffect(() => {

        if (authStatus === undefined) {
            checkIdentity(true).then((res) => {
                const response = ValidateResponse(res.data);

                if (response.response.environment === process.env.REACT_APP_NAME) {
                    setAuthStatus(response.status);
                }
                // switch environments
                else if (response.status === 'success' 
                    && window.location.host === "localhost:3000"
                    && auth !== null && auth.environment === response.response.environment) {
                        setAuthStatus(response.status);
                }
                else {
                    localStorage.clear();
                    console.log(response.response.environment + process.env.REACT_APP_NAME);
                    setAuthStatus('error');
                }
                
            }).catch((error) => {
                localStorage.clear();
                console.log(error);
                ValidateResponse(error);
                setAuthStatus('error');
            })
        }

    }, [authStatus]);

    if (authStatus === undefined) {
        return <></>
    }

    return auth && auth.token && authStatus === 'success' && auth.environment === process.env.REACT_APP_NAME
        ? <Outlet />
        : <Navigate to="/auth/login" replace state={{ from: location }} />;
};