/**
 * @see helpers/global
 */
import axios from 'axios';
import { API_URL, getApiHeaders } from "./api.js"

// POST @eric: wat is een arraybuffer en waarom zit die op invoices?
// const fetchInvoices = postData => {
//     return axios.post(`${API_URL}/customer/management/invoices`, postData, {responseType: 'arraybuffer'} + getApiHeaders())
// }
const fetchInvoices = postData => {
    return axios.post(`${API_URL}/customer/management/invoices`, postData, getApiHeaders())
}

const fetchRentalCases = postData => {
    return axios.post(`${API_URL}/customer/management/overviews/rental`, postData, getApiHeaders())
}

const fetchRepairs = postData => {
    return axios.post(`${API_URL}/customer/management/overviews/repairs`, postData, getApiHeaders())
}

const fetchDepotCases = postData => {
    return axios.post(`${API_URL}/customer/management/overviews/depot`, postData, getApiHeaders())
}

const fetchExcel = postData => {
    return axios.post(`${API_URL}/customer/management/overviews/excel`, postData, {responseType: 'arraybuffer', ...getApiHeaders()})
}

export{
    fetchInvoices,
    fetchRentalCases,
    fetchRepairs,
    fetchDepotCases,
    fetchExcel
}