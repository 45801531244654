import React from "react";
import { Navigate } from "react-router-dom";

// Segment auth
import { RoutesAuthLogin } from "./../../routes/Auth/Login"
import { RoutesAuthLogout } from "./../../routes/Auth/Logout"
import { RoutesAuthRegister } from "./../../routes/Auth/Register"
import { RoutesAuthForgot } from "./../../routes/Auth/Forgot"
import { RoutesAuthReset } from "./../../routes/Auth/Reset"

// + page not found ()
export const routes = [
    // Sement "Auth"
    {
        path: "/auth",
        pageTitle: "Autorisatie",
        redirect: <Navigate to={"/auth/login"} />,
        children: [
            {
                path: "/login", 
                pageTitle: "Inloggen",
                component: <RoutesAuthLogin />
            },
            {
                path: "/logout",
                pageTitle: "Uitloggen",
                component: <RoutesAuthLogout />
            },
            {
                path: "/register/:token",
                pageTitle: "Registreren",
                component: <RoutesAuthRegister />
            },
            {
                path: "/forgot",
                pageTitle: "Wachtwoord vergeten",
                component: <RoutesAuthForgot />
            },
            {
                path: "/reset/:token",
                pageTitle: "Wachtwoord veranderen",
                component: <RoutesAuthReset />
            }
        ]
    },
    // Redirects
    {  path: "/login", redirect: <Navigate to={"/auth/login"} /> },
    {  path: "/logout", redirect: <Navigate to={"/auth/logout"} /> },
]
