import React, { useState} from "react";
import { useParams } from "react-router-dom";
//import { Identity } from "../../../../components/Identity";
import { PageTopbar } from "../../../../components/Page/Topbar";
import { PageHeader } from "../../../../components/Page/Header";
import { AppRentalsCasesActionsRetour } from "../../../../app/Rentals/Cases/Actions/Retour";
import { getPageTitle} from "../../../../app/helpers/global";

export function RoutesRentalsCasesActionsRetour({ parents, current, tabGroup }) {

    const { rentalCaseId } = useParams();
    const [ title, setTitle] = useState(null);

    let group = tabGroup;
    if (title === null) {
        getPageTitle('rental', rentalCaseId, setTitle);
    }

    return (
        <div>
            <PageTopbar
                parents={parents}
                current={current}
            />

            {/* <Identity /> */}

            <PageHeader
                title={title}
                parents={parents}
                current={current}
                variables={{ rentalCaseId: rentalCaseId }}
                tabGroup={group}
            />

            <AppRentalsCasesActionsRetour
                parents={parents}
                rentalCaseId={rentalCaseId}
                setTitle={setTitle}
            />
        </div>
    );
}