import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ValidateResponse, fetchLocations, fetchDepartments } from "../../../helpers/global";
import { checkRequest } from "../../../helpers/customer";
import { getProductDetails, actionProductDepotOut } from "../../../helpers/articles";
import { FormFields } from "../../../../components/Form/Fields";
import { FormInputSubmit } from "../../../../components/Form/Input/Submit";
import { Message } from "../../../../components/Message";
import { ContentWrapper } from "../../../../components/Content/Wrapper";

export function AppArticlesProductActionsDepotout({ productId, parents }) {

    const auth = JSON.parse(localStorage.getItem('auth'));

    const navigate = useNavigate();
    const [state, setState] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [error, setError] = useState();
    const [locations, setLocations] = useState();
    const [departments, setDepartments] = useState();
    const [formErrors, setFormErrors] = useState({});
    const [values, setValues] = useState({ contactId: auth.userId });

    const updateLocation = (locationId) => {
        fetchDepartments(locationId).then((res) => {

            const response = ValidateResponse(res.data, 'departments');
            if (response.status === 'success') {
                setDepartments(response.departments);
            }
            else {
                setError(response.message);
            }
        })
    }

    useEffect(() => {

        checkRequest(productId + '/DEPOT-OUT').then((res) => {
            const response = ValidateResponse(res.data, 'status');
            if (response.status !== 'success') {
                setError(response.message);
            }
            setIsChecked(true);
        })
    }, [productId, navigate]);

    useEffect(() => {

        getProductDetails(productId).then((res) => {
            const response = ValidateResponse(res.data, 'details');
            if (response.status === 'success') {

                setState(response.details);

                let responseValues = {
                    ...values,
                    productId: productId,
                    locationId: response.details.ownerEntityId,
                    departmentId: response.details.departmentId,
                    room: response.details.room,
                }

                setValues(responseValues);
                setIsLoaded(true);
                updateLocation(response.details.ownerEntityId);
            }
            else {
                setError(response.message);
            }
        })
    }, [productId])

    useEffect(() => {
        fetchLocations().then((res) => {

            const response = ValidateResponse(res.data, 'locations');

            if (response.status === 'success') {
                setLocations(response.locations);
            }
            else {
                setError(response.message);
            }
        })
    }, [setError])

    const handleInput = (name, value, identifier) => {

        let newValues = values;

        if (identifier !== undefined && identifier !== null) {
            if (newValues[identifier] === undefined) {
                newValues[identifier] = {}
            }
            newValues[identifier][name] = value;
        }
        else {
            newValues[name] = value;
        }
        // Custom form behaviour
        if (name === "locationId") {
            updateLocation(value);
            newValues.room = null;
            newValues.departmentId = null;
        }

        setValues(newValues);
        return;
    }

    const sendValues = () => {

        let requiredValues = ['deliverDate', 'productId', 'locationId', 'contactId'];
        let validation = {};

        requiredValues.forEach((requiredField) => {
            if (values[requiredField] === undefined || values[requiredField] === null || values[requiredField].length === 0) {
                validation[requiredField] = 'Verplicht veld';
            }
        });

        if (Object.keys(validation).length !== 0) {
            setFormErrors(validation);
            return;
        }

        let postData = {
            'productId': values.productId,
            'departmentId': values.departmentId,
            'locationId': values.locationId,
            'room': values.room,
            'deliverDate': values.deliverDate,
            'client': values.client,
            'comments': values.comments,
            'contactId': values.contactId
        }

        //processOutDepotRequest
        actionProductDepotOut(postData).then((res) => {
            const response = ValidateResponse(res.data, 'status');
            if (response.status === 'success') {
                navigate("/" + parents.replace("actions", "details/") + productId, {
                    replace: true,
                    state: { message: response.message, type: response.status }
                });
            }
            else {
                setError(response.message);
            }
        }).catch((error) => {
            const response = ValidateResponse(error);
            setError(response.message);
        })
    }

    if (error !== undefined) {
        return (<Message type="error" message={error} />);
    }
    if (isLoaded === false || isChecked === false) {
        return (<Message type="loading" />);
    }
    if (state === undefined || state === null || Object.keys(state).length === 0) {
        return (<Message type="noData" />);
    }

    const fields = {
        'deliverDate': { type: "date", label: "Gewenste ophaaldatum", options: { min: null, max: "+1" } },
        'locationId': { type: 'select', label: "Locatie", options: locations },
        'departmentRoom': {
            type: "fieldset",
            options: {
                'departmentId': { type: 'select', label: "Afdeling", options: departments, className: 'col-8' },
                'room': { type: 'text', label: "Kamer", className: 'col-4' }
            }
        },
        'client': { type: 'text', label: "Naam client" },
        'contactId': { type: "contacts", label: "Contactpersoon" }, // , options: contacts
        'comments': { type: 'textarea', label: "Opmerkingen" },
    }

    const content = () => {
        return (
            <>
                <h3 className="box-header">Aanvraag levering uit depot</h3>

                <FormFields
                    formFields={fields}
                    setValues={handleInput}
                    setErrors={setFormErrors}
                    formValues={values}
                    formErrors={formErrors}
                />
                <FormInputSubmit
                    handleSubmit={sendValues}
                    cancelUri={"/" + parents + "/" + productId}
                />
            </>
        )
    }

    // Reservation message
    let reservationMessage;
    if (state.enableReservation === "1") {
        reservationMessage = state.reservationReason;
    }

    return (
        <>
            <Message
                message={reservationMessage}
                title={"Reservering actief"}
                type={'warning'}
            />
            <ContentWrapper
                type="outline-box"
                content={content()}
                className="outline-box--form"
            />
        </>
    );
}