import React, { useState } from "react";

/**
 * @see FormRender
 * 
 * let op: required fields & * voor label moeten in de parent gezet worden
 * 
 * Ranges: null = current year (validation now only on year! @todo: allow specific date)
 * use "+1" of "-1" to set one year from now of use static numerical year.
 * example 1: from 2000 to now = {min:2000, max:null}
 * example 2: form now to 2 year from now = {min:null, max:"+2"}
 * 
 * @return formattedDate : returns the (valid) date as YYYY-MM-DD or NULL
 */
export function FormInputDate({
    setValues, // required
    fieldName, // required
    fieldValue,  // optional
    fieldLabel,  // optional
    fieldError, // optional
    identifier, // optional
    range = {min:null, max:null} // required
}) 
{
    // alert for developer: set range!
    if (range.min === null && range.max === null) {
        alert('Range is not set!');
    }
const fieldId = fieldName + (identifier ?? "-form") + '-input';
    const [errors, setErrors] = useState({});
    const [date, setDate] = useState(fieldValue ?? null);

    const validateDate = () => {
        let day         = document.querySelector(`input#` +  fieldId + "-Day").value;
        let month       = document.querySelector(`input#` +  fieldId + "-Month").value;
        let year        = document.querySelector(`input#` +  fieldId + "-Year").value;
        let validation  = {};
        let formattedDate = null;


        // Range
        if (range.min === null) {
            range.min = new Date().getFullYear();
        }
        else if (typeof range.min === 'string' && range.min.includes('+') === true) {
            range.min = new Date().getFullYear() + parseInt(range.replace('+', ''));
        }
        else if (typeof range.min === 'string' && range.min.includes('-') === true) {
            range.min = new Date().getFullYear() - parseInt(range.min.replace('-', ''));
        }

        if (range.max === null) {
            range.max = new Date().getFullYear();
        }
        else if (typeof range.max === 'string' && range.max.includes('+') === true) {
            range.max = new Date().getFullYear() + parseInt(range.max.replace('+', ''));
        }
        else if (typeof range.max === 'string' && range.max.includes('-') === true) {
            range.max = new Date().getFullYear() - parseInt(range.max.replace('-', ''));
        }

        // Validate fields
        if (day.length > 0 && day > 31) {
            validation['Day'] = 'Ongeldige dag';
        }
        if (month.length > 0 && month > 12) {
            validation['Month'] = 'Ongeldige maand';
        }
        if (year.length > 0 &&  (year < range.min || year > range.max)) {
            validation['Year'] = 'Ongeldig jaartal (' + range.min + ' - ' + range.max + ')';
        }

        if (day && month && year) {

            let maxDay = new Date(year, month, 0).getDate();

            if (maxDay < day){
                validation['Day'] = 'Ongeldige dag van de maand';
            }

            // If everything is there without errors, there is a valid date
            if (Object.keys(validation).length === 0) {
                formattedDate = year + "-" + month + "-" + day;
            }
        }

        setErrors(validation);

        return formattedDate;
    }

    const getDateInput = (fieldName) => 
    {
        if (date === undefined || date === null) {
            return;
        }
        let dateParts = date.split('-');
        if (fieldName === 'Year' && dateParts[0] !== undefined) {
            return dateParts[0];
        }
        if (fieldName === 'Month' && dateParts[1] !== undefined) {
            return dateParts[1];
        }
        if (fieldName === 'Day' && dateParts[2] !== undefined) {
            return dateParts[2];
        }
    }

    const handleChange = (event) => {

        let name            = event.target.dataset.name;
        let value           = event.target.value;
        let validation      = errors;
        let returnValues    = {};
        let returnField     = fieldName ?? 'date';

        let regex = name === 'Year' ? /^[0-9]{4}$/ : /^[0-9]{0,2}$/;

        // Validate input value
        if (regex.test(value) === false) {
            validation[name] = 'Ongeldige waarde';
            setErrors(validation);
            return;
        }

        // Validate day
        if (name === 'Day' && value.length >= 2) {
            document.querySelector(`input#` +  fieldId + "-Month").focus();
        }
        else if (name === 'Month' && value.length >= 2) {
            document.querySelector(`input#` +  fieldId + "-Year").focus();
        }

        // Return formatted date to parent
        let date = validateDate();
        returnValues[returnField] = date;
        setDate(date);
        setValues(returnField, date, identifier);
    }

    return (
        <div className="form__row form__row--fieldset form__row--date">

            <div className="form__component">
                <div className="form__label">
                    {fieldError &&
                        <span style={{ color: 'red' }}>{fieldError}: </span>
                    }
                    {fieldLabel}
                </div>
            </div>

            <div className="form__component form__component--auto">
                <div className="form__element">
                    <div className="form__input">
                        <input 
                            type="text" 
                            id={fieldId + "-Day"}
                           // value={getDateInput("Day")}
                            placeholder={getDateInput("Day") ?? "Dag"}
                            data-name={"Day"}
                            maxLength={2}
                            onChange={handleChange} 
                        />
                    </div>
                    {errors && errors.Day && <span style={{ color: 'red' }}>{errors.Day}</span>}
                </div>
            </div>
            <div className="form__component form__component--auto">
                <div className="form__element">
                    <div className="form__input">
                        <input 
                            type="text" 
                            id={fieldId + "-Month"}
                            //value={getDateInput("Month")}
                            placeholder={getDateInput("Month") ?? "Maand"}
                            data-name={"Month"}
                            maxLength={2}
                            onChange={handleChange}  
                        />
                    </div>
                    {errors && errors.Month && <span style={{ color: 'red' }}>{errors.Month}</span>}
                </div>
            </div>
            <div className="form__component form__component--auto">
                <div className="form__element">
                    <div className="form__input">
                        <input 
                            type="text" 
                            id={fieldId + "-Year"}
                            // value={getDateInput("Year")}
                            // placeholder="Jaar" 
                            placeholder={getDateInput("Year") ?? "Jaar"}
                            data-name={"Year"}
                            maxLength={4}
                            onChange={handleChange} 
                        />
                    </div>
                    {errors && errors.Year && <span style={{ color: 'red' }}>{errors.Year}</span>}
                </div>
            </div>
            &nbsp;
        </div>
    )
}