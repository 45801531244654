import React, { useState, useEffect} from "react";
import { fetchRequestDetails } from "../helpers/requests";
import { ValidateResponse } from "../helpers/global";
import { Message } from "../../components/Message";
import { Details } from "../../components/Details";

export function AppRequestDetails({ customerRequestId, setTitle}) {

    const [state, setState] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {
        fetchRequestDetails(customerRequestId).then((res) => {

            const response = ValidateResponse(res.data, 'details');

            if (response.status === 'error') {
                setError(response.message, response.status);
            }
            else {
                setState(response.details);
                setIsLoaded(true);
                setTitle(response.details.title);
            }
        }).catch((error) => {
            const response = ValidateResponse(error);
            setError(response.message);
        });
        
    }, [customerRequestId, setTitle]);

    if (error !== undefined) {
        return (<Message type="error" message={error} />);
    }
    if (isLoaded === false) {
        return (<Message type="loading" />);
    }
    if (state === undefined || state === null || Object.keys(state).length === 0) {
        return (<Message type="noData" />);
    }

    let columns = [
        // west
        { key: "status", label: "Status", type: "text", gcId:"west"},
        { key: "type", label: "Type aanvraag", type: "text", gcId:"west"},
        { key: "customerRequestId", label: "Aanvraag id", type: "text", gcId:"west"},
        { key: "createDate", label: "Aanmaakdatum", type: "text", gcId:"west"},
        { key: "user", label: "Ingevoerd door", type: "text", gcId:"west"},
        { key: "contact", label: "Contactpersoon", type: "text", gcId:"west"},
    ];

    let data = state;

    if (data.customData !== undefined) {
        Object.keys(data.customData).forEach((key) => {
            columns.push({ key:key, label:data.customData[key].title, type:'html', gcId:"east"});
            data[key] = data.customData[key].value;
        });
    }

    return (
        <Details
            columns={columns}
            data={state}
            identifier="details"
        />
    );
}