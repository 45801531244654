import React, { useEffect, useState } from "react";
import { ValidateResponse, getCurrentPage, setCurrentPage } from "../helpers/global";
import { fetchCustomerRequests } from "../helpers/requests";
import { List } from "../../components/List";
import { ListPagination } from "../../components/List/Pagination";
import { Message } from "../../components/Message";
import { fetchContacts } from "../helpers/customer";

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function AppRequestsOverview({ current, setFilterFields, filterValues, identifier }) {

    let pageSize = 10; // default values ergens globaal opslaan?

    const [customerRequests, setCustomerRequests] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [loadFilters, setLoadFilters] = useState(true);
    const [pageNr, setPageNr] = useState(getCurrentPage(current));
    const [totalCount, setTotalCount] = useState(0);
    const [error, setError] = useState();
    const [filters, setFilters] = useState(JSON.parse(localStorage.getItem("filters" + identifier)));
    const [filterString, setFilterString] = useState("{}");

    if (JSON.stringify(filterValues) !== filterString) {
        setFilterString(JSON.stringify(filterValues));
        setIsLoaded(false);
        setFilters(filterValues);
        setCurrentPage(1, current, setPageNr);
    }

    if (pageNr !== getCurrentPage(current)) {
        setPageNr(getCurrentPage(current));
    }

    const handlePagination = (page) => {
        setCurrentPage(page, current, setPageNr);
    }

    let listColumns = [
        { key: "customerRequestId", label: "Id", type: "text" },
        { key: "type", type: "link", path: "details/:customerRequestId", hideView: "large" },
        { key: "type", label: "Type verzoek", type: "text", hideView: 'small' },
        { key: "createDate", label: "Datum", type: "date" },
        // { key: "requestLocation", label: "Locatie", type: "text" },
        { key: "requestLocationFrom", label: "Locatie van", type: "text" },
        { key: "requestLocationTo", label: "Locatie naar", type: "text" },
        // { key: "departmentRoom", label: "Afdeling/kamernr.", type: "text" },
        { key: "contact", label: "Contactpersoon", type: "text" },
        { key: "customerRequestId", type: "link", path: "details/:customerRequestId", hideView: "small" },
    ];

    const defineFilterFields = (contacts) => {

        const typesOptions = [
            { label: "Aanvraag depotopslag", value: "DEPOT-IN" },
            { label: "Leveren uit depot", value: "DEPOT-OUT" },
            { label: "Aanvraag verhuur", value: "RENTAL-REQUEST" },
            { label: "Verhuur beëindigen", value: "RENTAL-STOP" },
            { label: "Reparatie depotartikel", value: "REPAIR-DEPOT" },
            { label: "Reparatie verhuurartikel", value: "REPAIR-RENTAL" },
        ]

        // The filters are set here and send to parent onloaded
        let filterFields = {
            'location': { type: 'location', options: { locationId: "locationId" }, label: { locationId: "Locatie" } },
            'requestType': { type: 'select', label: "Type verzoek", options: typesOptions },
            'customerRequestId' : { type: 'text', label: "Verzoek ID"},
            'contact' : { type: 'select', label: "Contactpersoon", options:contacts}
        }

        setFilterFields(filterFields);
    }

    useEffect(() => {

        async function fetchData() {

            const callData = {
                filters: {
                    tabType: current,
                    limitOffset: pageSize * pageNr - (pageSize - 1),
                    limitRows: pageSize,
                    ...filters
                }
            }

            await fetchContacts(callData).then((res) => {
                const response = ValidateResponse(res.data, 'contacts');
                if (response.status === 'success') {
                   // setContacts(response.contacts);
                                            
                    if (loadFilters !== current) {
                        defineFilterFields(response.contacts);
                        setLoadFilters(current);
                    }
                }
            });

            await fetchCustomerRequests(callData).then((res) => {

                const response = ValidateResponse(res.data, 'requests');
                if (response.status === 'success') {

                    if (response.totalCount !== undefined) {
                        setTotalCount(response.totalCount);
                        
                        if (loadFilters !== current) {
                            setLoadFilters(current);
                        }
                    }
                    setCustomerRequests(response.requests);
                    setIsLoaded(true);
                }
                else {
                    setError(response.message);
                }
            }).catch((error) => {
                const response = ValidateResponse(error);
                setError(response.message);
            });
        }

        fetchData();
    }, [current, pageNr, filters, filterString, pageSize]);

    if (error !== undefined) {
        return (<Message type="error" message={error} />);
    }
    if (isLoaded === false) {
        return (<Message type="loading" />);
    }
    if (customerRequests === undefined || customerRequests === null || Object.keys(customerRequests).length === 0) {
        return (<Message type="noData" />);
    }

    return (
        <>
            <List
                columns={listColumns}
                data={customerRequests}
                identifier={identifier}
            />

            <div className={'spacer'}></div>

            <ListPagination
                currentPage={pageNr}
                setCurrentPage={handlePagination}
                totalCount={totalCount}
                pageSize={pageSize}
            />
            <div style={{ height: '30px' }}></div>
        </>
    );
}