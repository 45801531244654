import { routes as routesAuth } from "./auth"
import { routes as routesCustomer } from "./customer"

export const getRoutesConfig = () => {

    return {
        // routes available without authentication
        public: [ routesAuth ],
    
        // routes that need authentication
        protected: [ routesCustomer ],
    };
}
