/**
    use hideEmpty:true to hide if the data is null or undefined
    use gcId:"west"|"east" to force to west/east (by default data is split in half to west/east)
 */
import React, { useRef } from "react";
import { Message } from "./Message"; 
import { DetailsContent } from "./Details/Content"; 

export function Details({ columns, data, identifier = "view-component", listToCheck }) {

    const outlinebox = useRef(null); // deze haalt gegevens op van de dif die dit als ref heeft

    if (data === undefined || data.length === 0) {
        return (<Message type="noData" />);
    }

    // 1. remove empty
    columns.forEach((item, i) => {
        if (item.hideEmpty === true && (data[item.key] === undefined || data[item.key] === null)) {
            columns.splice(i, 1);
        }
    });

    // 2. divide in west/east
    let divided = [[], []];
    let divideOn = Math.ceil(columns.length / 2);
    let divideTo;

    columns.forEach((item, i) => {
        if (item.gcId !== undefined) {
            divideTo = item.gcId === 'east' ? 1 : 0;
        }
        else {
            divideTo = i >= divideOn ? 1 : 0;
        }

        divided[divideTo].push(item);
    });

    return (

        <div className="main">
            <div className={"container"}>
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="components outline-boxes">
                            {divided.map((columList, i) => (
                                <div key={identifier + "-outline-box-" + i} className="component">
                                    <div className="outline-box outline-box--details" ref={outlinebox} data-match >
                                        <DetailsContent columns={columList} data={data} identifier={identifier} listToCheck={listToCheck} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}