/**
 *    example column array let columns  = [
        {itemKey:"transportName", label:"Transport", type:"text"},
        {itemKey:"pickupWarehouses", label:"Inladen", type:"text"},
        {itemKey:"dropoffWarehouses", label:"Uitladen", type:"text"},
        {itemKey:"origin", label:"Oorsprong", type:"text"},
        {itemKey:"driverUser", label:"Chauffeur", type:"text"},
        {itemKey:"transportId", label:"", type:"link", path:"/transport/" + current + "/details/", variables:{transportId}}
    ]

    Only show in large (table) hideView:'small'
    Only show in small (boxes) hideView:'large'
 */
    import React, { useState } from "react";
    import { Link } from "react-router-dom";
    import { Message } from "./Message"; // mag misscien wel naar een file "messages" oid
    import { DetailsContent } from "./Details/Content";
    import { formatDate, resolvePath } from "../app/helpers/global";
    import { ContentDownload } from "./Content/Download";
    import { ContentWrapper } from "./Content/Wrapper";
    import { FormRender } from "./Form/Render";
    
    export function List({ columns, data, identifier = "list-component", handleAction }) {
    
        const [dataString, setDataString] = useState(JSON.stringify(data));
        const [error, setError] = useState();
    
        if (data === undefined || data.length === 0) {
            return (<Message type="noData" />);
        }
    
        // refresh component on data change.. 
        if (JSON.stringify(data) !== dataString) {
            setDataString(JSON.stringify(data));
        }
    
        // create smallview & largeview 
        let columnsLargeView = [];
        let columnsSmallView = [];
    
        columns.forEach((col) => {
            if (col.hideView === 'small') {
                columnsLargeView.push(col);
            }
            else if (col.hideView === 'large') {
                columnsSmallView.push(col);
            }
            else {
                columnsLargeView.push(col);
                columnsSmallView.push(col);
            }
        })
    
        /**
         * @see DetailsContent (copy)
         * Icons: remove, completed (=vinkje), add 
         */
        const setButtonClass = (item, data) => {
    
            let className = "button"
            let icon = item.icon;
    
            if (item.variableIcon !== undefined
                && data[item.variableIcon] !== null
                && data[item.variableIcon] !== undefined) {
                icon = data[item.variableIcon];
            }
    
            if (icon !== undefined && icon !== null) {
                className += " button--icon--" + icon;
            }
            else if (item.buttontext === undefined) {
                className += " button--hidden";
            }
            else {
                className += " button--transparant";
            }
            return className;
        }

        const setItemIcon = (item, value) => {

            let iconType = item.options[value] ?? null;
            
            if (iconType !== null) {
                return <span class={"icon-text icon-text--" + iconType}></span>
            }
        }
    
        const content = () => {
            return (
                <>
                    <Message message={error} type="error" />
    
                    {/** large screen: show as tabel */}
                    <div className="component d-none d-lg-block">
                        <table className="table-list" cellSpacing="0" cellPadding="0">
                            <thead>
                                <tr>
                                    {columnsLargeView.map((item, i) =>
                                        <th key={"list-label-" + item.key + i} className={"list-data-" + item.type}>{item.label}</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((row, i) => (
                                    <tr key={"list-view-table-" + identifier + i}>
                                        {columnsLargeView.map((item, c) => (
                                            <td key={"list-data-" + item.key + i + "-" + c} className={"list-data-" + item.type + (item.className !== undefined ? " " + item.className : "")}>
                                                {item.type === 'text' &&
                                                    <span>{row[item.key] ?? null}</span>
                                                }
                                                {item.type === 'date' &&
                                                    <span>{formatDate(row[item.key])}</span>
                                                }
                                                {/** make sure the parameters are available in the row! */}
                                                {item.type === 'link' &&
                                                    <Link to={resolvePath(item.path, row)} className="details-link details-link--arrow" />
                                                }
                                                {item.type === 'checkbox' &&
                                                    <FormRender
                                                        setValues={handleAction}
                                                        fieldType="checkbox"
                                                        fieldName={item.key + "__" + row[item.key]}
                                                        fieldValue={row[item.valueKey]}
                                                        setError={setError}
                                                    />
                                                }
    
                                                {item.type === 'download' &&
                                                    <ContentDownload
                                                        documentType={row.documentType ?? item.documentType}
                                                        documentId={row[item.key]}
                                                        display={item.display ?? 'button-download'}
                                                        fileName={(item.fileName ? row[item.fileName] : null)}
                                                        mimeType={item.mimeType && row[item.mimeType] ? row[item.mimeType] : 'application/pdf'}
                                                        documentName = {row[item.documentName] ?? null}
                                                        label={row[item.documentName] ?? item.label}
                                                    />
                                                }
    
                                                {item.type === 'button' && item.function !== undefined &&
                                                    <button
                                                        title={item.label}
                                                        type="button"
                                                        className={setButtonClass(item, row)}
                                                        onClick={() => item.function(row)}
                                                        setError={setError}
                                                    >{item.buttontext}</button>
                                                }
                                                {item.type === 'button' && item.function === undefined &&
                                                    <button
                                                        title={item.label}
                                                        type="button"
                                                        className={setButtonClass(item, row)}
                                                        setError={setError}
                                                    >{item.buttontext}</button>
                                                }
                                                {item.type === 'icon' &&
                                                    <>
                                                        {setItemIcon(item, row[item.key])}
                                                    </>
                                                }
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
    
                    {/** small screen: show blocks */}
                    <div className="outline-boxes d-lg-none">
                        {data.map((row, i) => (
                            <div className="component" key={"list-view-small-" + identifier + "-" + i} >
                                <div className="outline-box outline-box--details"/* ref={outlinebox} data-match*/ >
                                    <DetailsContent
                                        columns={columnsSmallView}
                                        data={row}
                                        identifier={"small-view-" + identifier}
                                        handleAction={handleAction}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            );
        }
    
        return (
            <ContentWrapper content={content()} type="page-component" />
        );
    }