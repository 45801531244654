import React, { useState} from "react";
import { useParams } from "react-router-dom";
//import { Identity } from "../../../components/Identity";
import { PageTopbar } from "../../../components/Page/Topbar";
import { PageHeader } from "../../../components/Page/Header";
import { AppRentalsCasesDocuments } from "../../../app/Rentals/Cases/Documents";
import { getPageTitle} from "../../../app/helpers/global";

export function RoutesRentalsCasesDocuments({ parents, current }) {

    const { rentalCaseId } = useParams();
    const [ title, setTitle] = useState(null);

    let group = (parents).replaceAll('/', '-');
    if (title === null) {
        getPageTitle('rental', rentalCaseId, setTitle);
    }

    return (
        <div>
            <PageTopbar
                parents={parents}
                current={current}
            />

            {/* <Identity /> */}

            <PageHeader
                title={title}
                parents={parents}
                current={current}
                variables={{ rentalCaseId: rentalCaseId }}
                tabGroup={group}
            />

            <AppRentalsCasesDocuments
                rentalCaseId={rentalCaseId}
                current={current}
                identifier={group}
            />
        </div>
    );
}