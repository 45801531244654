import { useRef, useState } from "react";
import { fetchDownload } from "../../app/helpers/global"
import { Message } from "../../components/Message"

/**
 * Display type icon/button, download/pdf
 */

export function ContentDownload({
    documentType, // required
    documentId, // 
    display = 'button-download',
    fileName,
    documentName,
    mimeType = "application/pdf",
    label,
    setError
}) {

    const [isLoading, setIsLoading] = useState(false);

    let displayType;
    let iconType;
    let imageIcon;

    if (display.indexOf('tile') !== -1) {
        let array = display.split('-');
        displayType = array[0];
        iconType = array[1];

        imageIcon = require("../../img/document-icon-" + iconType + ".jpg");
    }
    else {
        displayType = display.indexOf('icon') !== -1 ? 'icon' : 'button';
        iconType = display.indexOf('pdf') !== -1 ? 'pdf' : 'download';
    }


    const ref = useRef(null);

    if (fileName === undefined || fileName === null) {
        fileName = 'download.pdf';
    }

    const delay = ms => new Promise(res => setTimeout(res, ms));

    const closeDownload = async (delayTime = 2500) => {
        await delay(delayTime);
        setIsLoading(false);
    };

    const downloadFile = async () => {
        try {
            setIsLoading(true);
            await fetchDownload(documentType, documentId).then((res) => {

                if (res.data.size < 100) {
                    closeDownload(1);
                    if (setError !== undefined) {
                        setError('Er is iets foutgegaan met het ophalen van het bestand');
                    }
                    else {
                        alert('Er is iets foutgegaan met het ophalen van het bestand');
                    }
                }
                else {
                    const data = res.data;
                    var dataUrl = URL.createObjectURL(new Blob([data], { type: mimeType }));

                    let aLink = ref.current;
                    aLink.href = dataUrl;
                    aLink.setAttribute('download', fileName);
                    aLink.click();
                    URL.revokeObjectURL(dataUrl);

                }
                closeDownload();
            });

        } catch (error) {
            closeDownload(1);
            if (setError !== undefined) {
                setError('Er is iets foutgegaan met het ophalen van het bestand');
            }
            else {
                alert('Er is iets foutgegaan met het ophalen van het bestand');
            }
            console.log(error);
        }

    };

    if (documentId === null || documentId === undefined) {
        return (<></>);
    }

    return <>
        {isLoading === true &&
            <Message type="downloading" />
        }
        <a href={window.location.href} className="hidden" ref={ref} >&nbsp;</a>

        {(displayType === 'icon') &&
            <button
                type={"button"}
                onClick={() => { downloadFile() }}
                className={"button button--transparant button--" + iconType}
            >{documentName} </ button>
        }
        {displayType === 'button' &&
            <button
                title={label}
                type={"button"}
                onClick={() => { downloadFile() }}
                className={"button button--outline button--white button--" + iconType + " justify-content-center"}>
                {label}
            </ button>
        }
        {displayType === 'tile' &&
            <button
                title={label}
                type={"button"}
                onClick={() => { downloadFile() }}
                className={"button button--outline button--tile"}>

                <img className="file-icon" src={imageIcon} alt="" />
                <div className="file-name" >{label}</div>
            </ button>
        }
    </>
}