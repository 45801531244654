import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { setAccountPassword, checkGuid } from "../helpers/auth";
import { Message } from "../../components/Message";
import { ContentWrapper } from "../../components/Content/Wrapper";

export function AppAuthReset({ token }) {

    const [passwordShown, setPasswordShown] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [formError, setFormError] = useState();
    const [error, setError] = useState();
    const [success, setSuccess] = useState();

    let generalErrorMessage = "Er is iets misgegaan met het verwerken van uw verzoek";

    useEffect(() => {

        let data = { 'guid': token }

        if (token != null) {
            checkGuid(data).then((res) => {
                if (res.data.status !== 'success') {
                    setError(res.data.message ?? generalErrorMessage);
                }
            }).catch((error) => {
                console.log(error);
                setError(generalErrorMessage);
            })
        }
    }, [token, generalErrorMessage]);

    const onSubmit = data => {

        if (data.password !== data.passwordconfirm) {
            setFormError('De wachtwoord velden zijn niet identiek');
            return;
        }

        if (data.password === data.passwordconfirm && token != null) {
            let formData = {
                guid: token,
                password: btoa(data.password)
            }
            setAccountPassword(formData).then((res) => {

                if (res.data.status === "success") {
                    setSuccess("Uw wachtwoord is succesvol gewijzigd");
                }
                else {
                    setError(res.data.message ?? generalErrorMessage);
                }
            }).catch((error) => {
                console.log(error);
                setError(generalErrorMessage);
            })
        }
    }


    if (token === undefined || token === null) {
        return (<Message type="error" message={generalErrorMessage} />);
    }

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    const resetForm = () => {
        return (
            <div className="outline-box text-component">

                <Message type="error" message={formError} />

                <h3>Wachtwoord wijzigen</h3>
                <div id="result" style={{ color: 'red' }}>
                </div>
                <form className="form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="form__row">
                        <div className="form__component">
                            <label htmlFor="password" className="form__label">
                                {errors.password && <span style={{ color: 'red' }}>Verplicht veld: </span>}
                                Wachtwoord
                            </label>
                            <div className="form__element form__element--password">
                                <input
                                    id="password"
                                    className={"form-control"}
                                    type={passwordShown ? "text" : "password"}
                                    placeholder={"Nieuw wachtwoord"}{...register("password", { required: true })}
                                />
                                <div className="toggle-password"
                                    onClick={togglePasswordVisiblity}></div>
                            </div>
                        </div>
                    </div>
                    <div className="form__row">
                        <div className="form__component">
                            <label htmlFor="passwordconfirm" className="form__label">
                                {errors.passwordconfirm && <span style={{ color: 'red' }}>Verplicht veld: </span>}
                                Bevestig wachtwoord
                            </label>
                            <div className="form__element form__element--password">
                                <input className={"form-control"}
                                    id="passwordconfirm"
                                    type={passwordShown ? "text" : "password"}
                                    placeholder={"Bevestig wachtwoord"}{...register("passwordconfirm", { required: true })}
                                />
                                <div className="toggle-password"
                                    onClick={togglePasswordVisiblity}></div>
                            </div>
                        </div>
                    </div>
                    <div className="form__row form__row--buttons">
                        <div className="form__component">
                            <div className="form__element">
                                <button type="submit" className={"button button--primary"}>
                                    <span className={"me-1"}></span>
                                    Opslaan
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }

    const confirmMessage = (type, message) => {
        return (
            <div className="outline-box text-component">
                <Message type={type} message={message} />

                <div className="spacer"></div>

                <div className="form__component">
                    <div className="form__element">
                        <Link to={"/auth/login"} className={"button button--readmore"}>
                            <span className={"read-more"}></span>
                            Naar inlogpagina
                        </Link>
                    </div>
                </div>
            </div>
        );
    }

    let content;
    if (error !== undefined) {
        content = confirmMessage('error', error);
    }
    else if (success !== undefined) {
        content = confirmMessage('success', success);
    }
    else if (token !== undefined) {
        content = resetForm();
    }

    return (
        <>
            <ContentWrapper
                type="centred"
                content={content}
                className="outline-box--form"
            />
        </>
    );
}