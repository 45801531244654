import React from "react";
import { Link } from "react-router-dom";
import { validateRoute } from "./helpers/auth";

export function AppHome() {

    const auth = JSON.parse(localStorage.getItem('auth'))

    const content = () => {
        return (
            <>

                <div className="tiles__wrapper">
                    {validateRoute('/scan') === true &&
                        <div className="tiles__column">
                            <Link to={"/scan"} className={"tile tile__scannen active"}>
                                <span className="icon"></span>
                                <span className="tile__title">
                                    Scannen
                                </span>
                            </Link>
                        </div>
                    }
                    {validateRoute('/rentals/actions') === true &&
                        <div className="tiles__column">
                            <Link to={"/rentals/actions"} className={"tile tile__verhuur"}>
                                <span className="icon"></span>
                                <span className="tile__title">
                                    Verhuur
                                </span>
                            </Link>
                        </div>
                    }
                    {validateRoute('/management') === true &&
                        <div className="tiles__column">
                            <Link to={"/management"} className={"tile tile__management"}>
                                <span className="icon"></span>
                                <span className="tile__title">
                                    Management-informatie
                                </span>
                            </Link>
                        </div>
                    }
                    {validateRoute('/articles/actions') === true &&
                        <div className="tiles__column">
                            <Link to={"/articles/actions"} className={"tile tile__hulpmiddelen"}>
                                <span className="icon"></span>
                                <span className="tile__title">
                                    Hulpmiddelen-beheer
                                </span>
                            </Link>
                        </div>
                    }
                    {validateRoute('/requests') === true &&
                        <div className="tiles__column">
                            <Link to={"/requests"} className={"tile tile__aanvragen"}>
                                <span className="icon"></span>
                                <span className="tile__title">
                                    Lopende aanvragen
                                </span>
                            </Link>
                        </div>
                    }
                    <div className="tiles__column">
                        <a href="https://www.vandermarkzorghulpmiddelen.nl/aanvraag/" target="_blank" rel="noreferrer" className={"tile tile__aanvragen"}>
                            <span className="icon"></span>
                            <span className="tile__title">
                                Aanvraag ZVW
                            </span>
                        </a>
                    </div>
                </div>
            </>
        );
    }

    const footer = () => {
        return (
            <>
                <div className="outline-box outline-box--grey text-component">
                    <h4>Support</h4>
                    <div className="buttons flex-nowrap">
                        <button type="submit" className="button button--outline button--grey button--icon button--icon--phone">Bel</button>
                        <button type="submit" className="button button--outline button--grey button--icon button--icon--email">Mail</button>
                    </div>
                </div>
            </>
        );
    }

    const title = () => {
        return (
            <h3>Welkom <strong>{auth.fullName}</strong></h3>
        )
    }

    return (
        <div className="main">
            <div className="main__right">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-8 col-lg-6">
                        <div className="component" style={{ maxWidth: "600px" }}>

                            <div className="tiles-home-title">
                                {title()}
                            </div>

                            {content()}

                            <div className="tiles-home-footer">
                                {footer()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}