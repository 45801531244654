import React, { useState, useEffect } from "react";
import { fetchDepotDetails } from "../../helpers/articles";
import { ValidateResponse } from "../../helpers/global";
import { Message } from "../../../components/Message";
import { Details } from "../../../components/Details";
import { List } from "../../../components/List";
import { ContentWrapper } from "../../../components/Content/Wrapper";

export function AppArticlesDepotDetails({ depotCaseId, setTitle }) {

    const [state, setState] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {
        fetchDepotDetails(depotCaseId).then((res) => {

            const response = ValidateResponse(res.data, 'details');

            if (response.status === 'error') {
                setError(response.message, response.status);
            }
            else {
                setState(response.details);
                setTitle(response.details.name);
                setIsLoaded(true);
                localStorage.setItem("depotcase-" + depotCaseId, JSON.stringify(response.details))
            }
        }).catch((error) => {
            const response = ValidateResponse(error);
            setError(response.message);
        });

    }, [depotCaseId, setTitle]);

    if (error !== undefined) {
        return (<Message type="error" message={error} />);
    }
    if (isLoaded === false) {
        return (<Message type="loading" />);
    }
    if (state === undefined || state === null || Object.keys(state).length === 0) {
        return (<Message type="noData" />);
    }

    let allColums = [
        // west
        { key: "formType", label: "Type opdracht", type: "text", gcId: "west" },
        { key: "statusLabel", label: "Status", type: "text", gcId: "west" },
        { key: "createDate", label: "Aanmaakdatum", type: "date", gcId: "west" },
        { key: "handleDate", label: "Verwerkingsdatum", type: "date", gcId: "west" },
        { key: "pickupAddress", label: "Ophaaladres", type: "text", gcId: "west" },
        { key: "dropoffAddress", label: "Leveradres", type: "text", gcId: "west" },


        // east lastAction
        { key: "lastAction", label: "Laaste actie", type: "text", gcId: "east" },
        { key: "timeSpent", label: "Tijd op locatie", type: "text", gcId: "east" },
        { key: "priorityCall", label: "Spoedopdracht", type: "text", gcId: "east" },
        { key: "invoiceable", label: "Factureerbaar", type: "text", gcId: "east" },
        { key: "remarks", label: "Opmerkingen", type: "text", gcId: "east" },
    ];

    // Set/remove status-specific fields
    let viewColumns = [];

    allColums.forEach((row) => {
        if ((state.pickupAddress !== null || row.key !== 'pickupAddress')
            && (state.dropoffAddress !== null || row.key !== 'dropoffAddress')
            && !(state.depotStatus !== 'COMPLETED' && row.key==='handleDate')
        ) {
            viewColumns.push(row);
        }
    });

    let listColumns = [
        { key: "name", label: "Naam hulpmiddel", type: "text"},
        { key: "depotStatus", label: "Huidige depotstatus", type: "text"},
        { key: "productStatus", label: "Huidige productstatus", type: "text"}
    ]

    return (
        <>
            <Details
                columns={viewColumns}
                data={state}
            />

            <div className={'spacer--line'}></div>

            <ContentWrapper content = "Hulpmiddelen"  type="title" className={"page-title"}/>

            <List
                columns={listColumns}
                data={state.products ?? {}}
            />
        </>
    );
}