import React, { useState, useEffect } from "react";
import { ValidateResponse } from "../../../helpers/global";
import { checkRequest, fetchContacts } from "../../../helpers/customer";
import { actionProductRepair } from "../../../helpers/articles";
import { useNavigate } from "react-router-dom";
import { FormFields } from "../../../../components/Form/Fields";
import { FormInputSubmit } from "../../../../components/Form/Input/Submit";
import { Message } from "../../../../components/Message";
import { ContentWrapper } from "../../../../components/Content/Wrapper";

export function AppArticlesProductActionsRepair({ sourceId, redirectUri, cancelUri, requestType }) {

    const navigate = useNavigate();
    const [error, setError] = useState();
    const [formErrors, setFormErrors] = useState({});
    const [values, setValues] = useState({sourceId:sourceId});
    const [isChecked, setIsChecked] = useState(false);
    const [contacts, setContacts] = useState();

    useEffect(() => {

        checkRequest(sourceId + '/' +  requestType).then((res) => {
            const response = ValidateResponse(res.data, 'status');
            if (response.status !== 'success') {
                setError(response.message);
            }
            setIsChecked(true);
        })
    }, [sourceId, requestType, navigate]);

    useEffect(() => {

        fetchContacts().then((res) => {
           const response = ValidateResponse(res.data, 'contacts');
           if (response.status === 'success') {
               setContacts(response.contacts)
               response.contacts.forEach((contact) => {
                if(contact.selected !== undefined){
                    let newValues = {...values,                   
                        contactId: contact.value
                    };
                    setValues(newValues)
                }
            });
           }
       });      
    }, []);

    if (isChecked === false) {
        return (<Message type="loading" />);
    }

    const handleInput = (name, value, identifier) => {

        let newValues = values;

        if (identifier !== undefined && identifier !== null) {
            if (newValues[identifier] === undefined) {
                newValues[identifier] = {}
            }
            newValues[identifier][name] = value;
        }
        else {
            newValues[name] = value;
        }
        setValues(newValues);
        return;
    }

    const sendValues = () => {

        let requiredValues = ['description', 'contactId'];

        // Validate required fields
        let validation = {};

        requiredValues.forEach((requiredField) => {
            if (values[requiredField] === undefined || values[requiredField] === null || values[requiredField].length === 0) {
                validation[requiredField] = 'Verplicht veld';
            }
        });

        if (Object.keys(validation).length !== 0) {
            setFormErrors(validation);
            return;
        }

        let postData = {
            'sourceId': sourceId,
            'requestType': requestType,
            'description': values.description,
            'files': values.files,
            'contactId': values.contactId
        }

        actionProductRepair(postData).then((res) => {

            const response = ValidateResponse(res.data, 'status');

            if (response.status === 'success') {
                navigate(redirectUri, {
                    replace: true,
                    state: { message: response.message, type: response.status }
                });
            }
            else {
                setError(response.message);
            }
        }).catch((error) => {
            const response = ValidateResponse(error);
            setError(response.message);
        })
    }

    if (error !== undefined) {
        return (<Message type="error" message={error} />);
    }

    const fields = {
        'description': { type: 'textarea', label: "Beschrijving defect" },
        'files': { type: "file", label: "Afbeelding toevoegen" },
        'contactId': {type: "select", label: "Contactpersoon", options: contacts}
    }


    const content = () => {
        return (
            <>
                <h3 className="box-header">Reparatieverzoek indienen</h3>

                <FormFields
                    formFields={fields}
                    setValues={handleInput}
                    setErrors={setFormErrors}
                    formValues={values}
                    formErrors={formErrors}
                />
                <FormInputSubmit
                    handleSubmit={sendValues}
                    cancelUri={cancelUri}
                />
            </>
        )
    }

    return (
        <ContentWrapper
            type="outline-box"
            content={content()}
            className="outline-box--form"
        />
    );
}