import React, { useState } from "react";
import { actionProductProcessClientrepair } from "../../../helpers/articles";
import { ValidateResponse } from "../../../helpers/global";
import { useNavigate } from "react-router-dom";
import { FormFields } from "../../../../components/Form/Fields";
import { FormInputSubmit } from "../../../../components/Form/Input/Submit";
import { Message } from "../../../../components/Message";
import { ContentWrapper } from "../../../../components/Content/Wrapper";

export function AppArticlesProductActionsClientrepair({ productId, parents }) {

    const auth = JSON.parse(localStorage.getItem('auth'));
    const today = new Date();
    const currentDate = today.getFullYear() + '-' + (today.getMonth()+1 )+ '-' + today.getDate()
    const navigate = useNavigate();
    const [error, setError] = useState();
    const [formErrors, setFormErrors] = useState({});
    const [values, setValues] = useState({contactId: auth.userId, repairDate: currentDate});

    const handleInput = (name, value) => {

        let newValues = values;

        newValues[name] = value;
        
        setValues(newValues);
        return;
    }

    const sendValues = () => {

        let postData = values;

        postData['productId'] = productId;
        postData['repairUserId'] = values.contactId;

        let requiredValues = ['repairDate', 'conclusion', 'repairUserId'];

        // Validate required fields
        let validation = {};

        requiredValues.forEach((requiredField) => {
            if (postData[requiredField] === undefined) {
                // setError('Veld niet gevonden: ' + requiredField);
                validation[requiredField] = 'Verplicht veld';
            }
            else if (postData[requiredField] === null || postData[requiredField].length === 0) {
                validation[requiredField] = 'Verplicht veld';
            }
        });

        if (Object.keys(validation).length !== 0) {
            setFormErrors(validation);
            return;
        }

        actionProductProcessClientrepair(postData).then((res) => {

            const response = ValidateResponse(res.data, 'status');
            if (response.status === 'success') {
                navigate("/" + parents.replace("actions", "details/") + productId, {
                    replace: true,
                    state: { message: response.message, type:response.status}
                });
            }
            else {
                setError(response.message);
            }
        }).catch((error) => {
            const response = ValidateResponse(error);
            setError(response.message);
        })
    }

    let fields = {
            'repairDate': { type: 'date', label:'Reparatiedatum'},
            'conclusion' : {type: 'radio', label:'Resultaat reparatie', options:[{label: 'Gerepareerd', value: 'FIXED'}, {label:'Niet te repareren (afgevoerd)', value:'BROKEN'}]},
            'remark': { type: 'textarea', label: "Omschrijving" },
            'contactId' : {type: 'contacts', options: {contactId:"contactId"}, label:'Reparateur'}
    }

    let title = "Reparatie registreren";

    const content = () => {
        return <>
            <h3 className="box-header">{title}</h3>
            <FormFields
                formFields={fields}
                setValues={handleInput}
                setErrors={setFormErrors}
                formValues={values}
                formErrors={formErrors}
                identifier="product-add"
            />
            <FormInputSubmit
                handleSubmit={sendValues}
                cancelUri={"/" + parents + "/" + productId}
                submitLabel={"Verzenden"}
            />
        </>
    }

    return (
        <>
            <Message type="error" message={error} />
            <ContentWrapper
                type="outline-box"
                content={content()}
                className="outline-box--form"
            />
        </>
    );
}