import React, { useState } from "react";
//import { Identity } from "../../../components/Identity";
import { PageTopbar } from "../../../components/Page/Topbar";
import { PageHeader } from "../../../components/Page/Header";
import { AppArticlesDepotCases } from "../../../app/Articles/Depot/Cases";

export function RoutesArticlesDepotArchive({ title, parents, current }) {

    let identifier = (parents + current).replaceAll('/', '-');

    const [filterFields, setFilterFields] = useState([]);
    const [filterValues, setFilterValues] = useState(JSON.parse(localStorage.getItem("filters" + identifier)) ?? {});
    const [refresh, setRefresh] = useState(false);

    const resetFilterValues = (values) => {
        setFilterValues(values);
        setRefresh(refresh === false);
    }

    return (
        <div>
            <PageTopbar
                parents={parents}
                current={current}
            />

            {/* <Identity /> */}

            <PageHeader
                title={title}
                tabGroup="product-overview"
                parents={parents}
                current={current}
                filterFields={filterFields}
                setFilterValues={resetFilterValues}
                identifier={identifier}
            />

            <AppArticlesDepotCases
                current={current}
                filterValues={filterValues}
                setFilterFields={setFilterFields}
                identifier={identifier}
            />
        </div>
    );
}