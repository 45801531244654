import React, { useEffect, useState } from "react";
import { ValidateResponse, fetchInspectionTypes} from "../../../app/helpers/global";
import { Message } from "../../Message";
import { FormRender } from "../Render";

export function FormInputInspectionType({
    setValues, // required
    fieldValue = {},  // data object {locationId:.., departmentId:.., room:..} optional
    fieldLabel = { inspectionType: "Type keuring"},  // optional
    identifier, // optional
    fields = { inspectionType: "inspectionType"} // choose the fields to show on location & pass the names. 
}) {

    const [error, setError] = useState();
    const [inspectionTypes, setInspectionTypes] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {

        fetchInspectionTypes(1).then((res) => {

            const response = ValidateResponse(res.data, 'inspectionTypes');

            if (response.status === 'success') {
                setInspectionTypes(response.inspectionTypes);
                setIsLoaded(true);
            }
            else {
                setError(response.message);
            }
        }).catch((error) => {
            const response = ValidateResponse(error, 'status');
            setError(response.message);
        });

    }, []);

    const handleValues = (name, value) => {
        setValues(name, value, identifier);
    }

    if (error !== undefined) {
        return (<Message type="error" message={error} />);
    }
    if (isLoaded === false) {
        return (<Message type="loading" />);
    }
    if (inspectionTypes === undefined || inspectionTypes === null || Object.keys(inspectionTypes).length === 0) {
        return (<Message type="noData" />);
    }

    return (
        <>
            {inspectionTypes.length > 0 &&
                <FormRender
                    setValues={handleValues}
                    fieldType="select"
                    fieldName={fields.inspectionType}
                    fieldValue={fieldValue.inspectionType}
                    fieldLabel={fieldLabel.inspectionType}
                    identifier={identifier}
                    fieldOptions={inspectionTypes}
                />
            }
        </>
    )
}