import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../img/logo-vandermark.jpg";

export function PageTopbar({ parents, current }) {

    const [trail, setTrail] = useState();
    const [openMenu, setOpenMenu] = useState(false);
    const auth = JSON.parse(localStorage.getItem('auth'));
    const tree = JSON.parse(localStorage.getItem("routes-tree"));
    const mainMenu = JSON.parse(localStorage.getItem("main-menu"));

    const buildBreadCrumbs = () => {

        if (current === undefined) {
            setTrail([]);
            return;
        }

        let breadCrumbTrail = []
        let segments = parents === undefined ? [] : parents.split('/');
        let currentKey = parents === undefined ? current : parents.replace('/', '_') + "_" + current;

        if (current === 'home' || current === undefined) {
            setTrail(breadCrumbTrail);
            return;
        }

        if (current !== undefined) {
            segments.push(current);
        }

        let pages = [];
        segments.forEach((page) => {

            pages.push(page);
            let key = pages.join("_");

            if (tree[key] !== undefined && key !== currentKey && tree[key].pageTitle !== undefined && tree[key].pageTitle !== null) {
                breadCrumbTrail.push({ path: "/" + pages.join("/"), title: tree[key].pageTitle, segment: page });
            }
        });

        setTrail(breadCrumbTrail);
    }

    if (tree !== undefined && trail === undefined) {
        buildBreadCrumbs();
    }

    const handleMenuToggle = () => {
        setOpenMenu(!openMenu)
    }

    const closeMenu = () => {
        setOpenMenu(false)
    }

    return (
        <div className="topbar">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="topbar__inner">

                            {/** logo */}
                            {trail !== undefined && trail.length === 0 &&
                                <div className="topbar__logo">
                                    <img src={logo} alt="Logo Van der Mark" height="24" />
                                </div>
                            }

                            {/** Bread crumb trail */}
                            {auth !== null &&
                                <>

                                    {trail !== undefined && trail !== null && trail.length > 0 &&
                                        <>
                                            <a key="bread-crumb-home" href="/home" className="trail-segment topbar__home">&nbsp;</a>
                                            <div className="bread-crumb-trail">

                                                {trail.map((trail, i) => (
                                                    <a key={"bread-crumb-" + trail.segment} href={trail.path} className="trail-segment topbar__previous">
                                                        <div className={'topbar__title topbar__title--' + (i === 0 ? 'icon topbar__title--' + trail.segment : 'segment')}>
                                                            {trail.title}
                                                        </div>
                                                    </a>
                                                ))}
                                            </div>
                                        </>
                                    }

                                    {/** User identiy & top menu right */}
                                    <div className="topbar__right">
                                        <div className="topbar__user">
                                            <nav className={`user-dropdown ${openMenu ? "user-dropdown--active" : ""}`}>
                                                <div className="user-dropdown__top" onClick={handleMenuToggle}>
                                                    <div className="icon icon--user">

                                                    </div>
                                                    <div className="user-dropdown__name">
                                                        {auth.fullName}
                                                    </div>
                                                </div>
                                                <nav className="user-dropdown__bottom">
                                                    <nav className="user-dropdown__bottom__items" id="user-dropdown__bottom__items">

                                                        {mainMenu !== undefined && mainMenu !== null && mainMenu.length > 0 &&
                                                            <ul className="main-menu" >
                                                                {mainMenu.map((menu, i) => (
                                                                    <li key={menu.path.replace('/', '-')}>
                                                                        <NavLink to={menu.path} onClick={() => closeMenu()}>
                                                                            {menu.title}
                                                                        </NavLink>
                                                                    </li>
                                                                ))}
                                                                <li>
                                                                    <NavLink to="https://www.vandermarkzorghulpmiddelen.nl/aanvraag/" onClick={() => closeMenu()}>
                                                                        Aanvraag ZVW
                                                                    </NavLink>
                                                                </li>
                                                                <li>
                                                                    <NavLink to="/auth/logout" onClick={() => closeMenu()}>
                                                                        Uitloggen
                                                                    </NavLink>
                                                                </li>
                                                            </ul>
                                                        }
                                                    </nav>
                                                </nav>
                                            </nav>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}