import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { PageTopbar } from "../components/Page/Topbar";
import { checkIdentity } from "../app/helpers/auth";
import { ValidateResponse } from "../app/helpers/global";
import { Message } from "../components/Message"

export function RoutesNotfound() {

    const [ authStatus, setAuthStatus ] = useState();
    const auth = JSON.parse(localStorage.getItem('auth'));
    let page = window.location.pathname;

    useEffect(() => {

        checkIdentity(true).then((res) => {
            const response = ValidateResponse(res.data);
            setAuthStatus(response);
        }).catch((error) => {
            console.log(error);
            const response = ValidateResponse(error);
            setAuthStatus(response);
        })
    }, []);

    
    if (authStatus !== undefined && authStatus.status === 'success' && auth !== null && auth !== undefined) {
        
        if (page === "/home") {
            window.location.reload();
            return <></>
        }
        else if (page === "/") {
            return <Navigate to="/home" />
        }
        else {
            return (
                <>
                    <PageTopbar />
                    <Message message="Pagina niet gevonden" />
                </>
            );
        }
    }
    else if (authStatus !== undefined) {
        localStorage.clear();
        return <Navigate to="/auth/login" />
    }
    else {
        return <></>
    }
}