/*
 *    example column array let columns  = [
        {itemKey:"transportName", label:"Transport", type:"text"},
        {itemKey:"pickupWarehouses", label:"Inladen", type:"text"},
        {itemKey:"dropoffWarehouses", label:"Uitladen", type:"text"},
        {itemKey:"origin", label:"Oorsprong", type:"text"},
        {itemKey:"driverUser", label:"Chauffeur", type:"text"},
        {itemKey:"transportId", label:"", type:"link", path:"/transport/" + current + "/details/", variables:{transportId}}
    ]

    use hideEmpty:true to hide if the data is null or undefined
    use gcId:"west"|"east" to force to west/east (by default data is split in half to west/east)
 */
//import React, { useState, useEffect, useRef } from "react";
import { Message } from "../Message"; // mag misscien wel naar een file "messages" oid
import { Link } from "react-router-dom";
import { resolvePath, formatDate } from "../../app/helpers/global";
import { ContentDownload } from "../Content/Download";
import { FormRender } from "../Form/Render";

export function DetailsContent({ columns, data, identifier = "view-component", handleAction, boxClass = "details-row", setError}) {

    const getBoxClass = (type, label) => {

        if (type === 'link') {
            return ''; // 'details-link details-link--arrow';
        }

        else if (type === "box") {
            return "outline-box";
        }
        else if (type === "details-title") {

            let className = "details-title";
            if (label !== undefined && label !== null) {
                className += " details-title--icon details-title--icon--" + label;
            }
            return className;
        }

        //details-row details-row--label-line 
        let className = boxClass;
        if (label !== undefined && label !== null) {
            className += " " + boxClass + "--label-line";
        }
        else {
            className += " " + boxClass + "--no-label";
        }

        return className;
    }

    if (data === undefined || data.length === 0) {
        return (<Message type="noData" />);
    }

    const getValue = (item, data) => {
        return data[item.key] ?? (item.value ?? null)
    }

    /**
     * @see List (copy)
     * Icons: remove, completed (=vinkje), add 
     */
    const setButtonClass = (item, data) => {

        let className = "button"
        let icon = item.icon;

        if (item.variableIcon !== undefined
            && data[item.variableIcon] !== null
            && data[item.variableIcon] !== undefined) {
            icon = data[item.variableIcon];
        }

        if (icon !== undefined && icon !== null) {
            className += " button--icon--" + icon;
        }
        else if (item.buttontext === undefined) {
            className += " button--hidden";
        }
        else {
            className += " button--transparant";
        }
        return className;
    }

    return (
        <>
            {columns.map((item, i) => (

                <div key={"details-item-" + identifier + item.key + "-" + i} className={getBoxClass(item.type, item.label)}>

                    {item.type === 'title' &&
                        <div className="title">
                            <span className={getBoxClass("details-title", item.icon)}>
                                {columns[i].content ?? (data[columns[i].key] ?? null)}
                            </span>
                        </div>
                    }

                    {item.type === 'link' &&
                        <Link
                            to={resolvePath(item.path, data)}
                            className="details-link details-link--arrow">
                            {data[columns[i].key] ?? (columns[i].label ?? null)}
                        </Link>
                    }
                    {item.type === 'box' &&

                        <div className="details-row">
                            <div className="details-row__column">
                                <div className="label">{item.label}</div>
                                <div className="value" >
                                    <div dangerouslySetInnerHTML={{ __html: getValue(item, data) }} />
                                </div>
                            </div>
                        </div>
                    }

                    {item.type === 'download' &&

                        <ContentDownload
                            documentType={data.documentType ?? item.documentType}
                            documentId={data[item.key]}
                            display={item.display ?? 'button-download'}
                            fileName={(item.fileName ? data[item.fileName] : null)}
                            mimeType={item.mimeType && data[item.mimeType] ? data[item.mimeType] : 'application/pdf'}
                            documentName = {data[item.documentName] ?? null}
                            label={data[item.documentName] ?? item.label}
                        />
                    }
                    {item.type === 'checkbox' &&
                        <FormRender
                            setValues={handleAction}
                            fieldType="checkbox"
                            fieldName={item.key + "__" + data[item.key]}
                            fieldValue={data[item.valueKey]}
                            identifier={identifier}
                        />
                    }
                    {item.type === 'button' &&
                        <button
                            title={item.label}
                            type="button"
                            className={setButtonClass(item, data)}
                            onClick={() => item.function(data)}
                        >{item.buttontext}</button>
                    }
                    {item.type !== 'title'
                        && item.type !== 'link'
                        && item.type !== 'box'
                        && item.type !== 'download'
                        && item.type !== 'button'
                        && item.type !== 'checkbox'
                        &&
                        <>
                            <div className="details-row__column">
                                <div className="label">
                                    {item.label}
                                </div>
                            </div>

                            <div className="details-row__column">
                                <div className="value">
                                    {item.type === 'text' &&
                                        <span>{getValue(item, data)}</span>
                                    }
                                    {item.type === 'html' &&
                                        <span dangerouslySetInnerHTML={{ __html: getValue(item, data) }} />
                                    }
                                    {item.type === 'date' &&
                                        <span>{formatDate(getValue(item, data))}</span>
                                    }
                                </div>
                            </div>
                        </>
                    }
                </div >
            ))
            }
        </>
    );
}