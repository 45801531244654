import React, { useEffect, useState } from "react";
import { ValidateResponse} from "../../../app/helpers/global";
import { fetchContacts  } from "../../../app/helpers/customer";
import { Message } from "../../Message";
import { FormRender } from "../Render";

export function FormInputContacts({
    setValues, // required
    fieldValue = {},
    fieldLabel = { contactId: "Conctactpersoon"},  // optional
    identifier, // optional
    fields = { contactId: "contactId"} // choose the fields to show on location & pass the names. 
}) {

    const [error, setError] = useState();
    const [contacts, setContacts] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {

        fetchContacts().then((res) => {

            const response = ValidateResponse(res.data, 'contacts');

            if (response.status === 'success') {
                setContacts(response.contacts);
                setIsLoaded(true);
            }
            else {
                setError(response.message);
            }
        }).catch((error) => {
            const response = ValidateResponse(error, 'status');
            setError(response.message);
        });

    }, []);

    const handleValues = (name, value) => {
        setValues(name, value, identifier);
    }

    if (error !== undefined) {
        return (<Message type="error" message={error} />);
    }
    if (isLoaded === false) {
        return (<></>);
    }

    return (
        <>
            {contacts.length > 0 &&
                <FormRender
                    setValues={handleValues}
                    fieldType="select"
                    fieldName={fields.contactId}
                    fieldValue={fieldValue.contactId ?? ""}
                    fieldLabel={fieldLabel.contactId}
                    identifier={identifier}
                    fieldOptions={contacts}
                />
            }
        </>
    )
}