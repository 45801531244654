import React from "react";
import { Link } from "react-router-dom";

export function FormInputSubmit({
    handleSubmit,
    cancelUri,
    cancelFunction,
    cancelLabel = "Annuleren",
    submitLabel = "Opslaan",
    className="button--primary submit"
}) {

    return (
        <>
            <div className="form__row--bb"></div>

            <div className="form__row form__row--buttons">
                <div className="form__component">
                    <div className="form__element">
                        <button type="submit" onClick={handleSubmit} className={"button " + className}>{submitLabel}</button>
                    </div>
                </div>
                {cancelUri !== undefined && cancelUri !== null &&
                    <div className="form__component">
                        <div className="form__element">
                            <Link to={cancelUri} className={"button button--outline"}>
                                <span>{cancelLabel}</span>
                            </Link>
                        </div>
                    </div>
                }
                {cancelFunction !== undefined &&
                    <div className="form__component">
                        <div className="form__element">
                            <button onClick={cancelFunction} className={"button button--outline"}>{cancelLabel}</button>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}